import React from 'react';
import MobileAppLayout from '../../components/layout/MobileAppLayout';
import useCurrentUser from '../../hooks/useCurrentUser';
import MobileAppAuthenticationContent from './MobileAppAuthenticationContent';
import MobileAppProfileContent from './MobileAppProfileContent';
import { useTranslation } from 'react-i18next';

const MobileAppProfilePage: React.FC = () => {
  const { isUserAuthenticated } = useCurrentUser();
  const { t } = useTranslation();

  return (
    <MobileAppLayout title={t('my_profile_page.title')}>
      {!isUserAuthenticated
        ? <MobileAppAuthenticationContent />
        : <MobileAppProfileContent />
      }
    </MobileAppLayout>
  );
};

export default React.memo(MobileAppProfilePage);
