const { boolean } = require('boolean');

interface Config {
  apiUrl?: string;
  photonApiUrl?: string;
  userProfile: {
    firstNameMaxLength: number;
    lastNameMaxLength: number;
    passwordMinLength: number;
    profilePhotoCompression: {
      quality: number;
      maxWidth: number;
      maxHeight: number;
    };
    allowedProfilePhotoTypes: string[];
  };
  mediaObjectMaxUploadSize: number; // Bytes
  facebookPermissions: string[];
  isDebug: boolean;
}

const config: Config = {
  apiUrl: process.env.REACT_APP_API_URL,
  photonApiUrl: 'https://photon.komoot.io',
  userProfile: {
    firstNameMaxLength: 20,
    lastNameMaxLength: 20,
    passwordMinLength: 6,
    profilePhotoCompression: {
      quality: 0.8,
      maxWidth: 1080,
      maxHeight: 1080,
    },
    allowedProfilePhotoTypes: ['image/jpeg', 'image/png', 'image/webp'],
  },
  mediaObjectMaxUploadSize: 1048576,  // 1024 kB
  facebookPermissions: ['email'],
  isDebug: boolean(process.env.REACT_APP_DEBUG),
};

export default Object.freeze(Object.assign({}, config));
