class ForbiddenError extends Error {
  readonly _data: any;

  constructor(message: string, data?: any) {
    super(message);
    this.name = 'ForbiddenError';
    this._data = data;
  }

  get data() {
    return this._data;
  }
}

export default ForbiddenError;
