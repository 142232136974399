import { useQuery } from 'react-query';
import userModel from '../model/user';
import NetworkError from '../errors/NetworkError';
import { showToast } from '../actions/toastActions';
import { useDispatch } from 'react-redux';
import config from "../config/config";

const useCurrentUser = (onUserSuccess?: any) => {
  const dispatch = useDispatch();

  const onError = (error: Error) => {
    if (error instanceof NetworkError) {
      dispatch(showToast('Looks like you don\'t have a connection to the internet.', true));
    }
  };

  const {
    status,
    isIdle: isUserIdle,
    isFetching: isUserFetching,
    isSuccess: isUserSuccess,
    isError: isUserError,
    data: userData,
    refetch: refetchUser
  } = useQuery('user', userModel.getCurrentUser, {
      refetchOnWindowFocus: false,
      retry: 0,
      // staleTime: 500,
      onSuccess: onUserSuccess,
      onError: onError
  });

  const isUserAuthenticated = !!userData;

  const userFullName = `${userData?.firstName} ${userData?.lastName}`;

  const userProfilePhotoFile = userData?.profilePhoto?.contentUrl;
  const userProfilePhoto = userProfilePhotoFile
    ? `${config.apiUrl}${userProfilePhotoFile}`
    : null;

  return { status, isUserIdle, isUserFetching, isUserSuccess, isUserError, userData, refetchUser, isUserAuthenticated, userFullName, userProfilePhoto };
};

export default useCurrentUser;
