import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { IonIcon, IonLabel, IonRouterOutlet, IonTabBar, IonTabButton, IonTabs } from '@ionic/react';
import { calendarOutline, homeOutline, peopleCircleOutline, peopleOutline, personCircleOutline } from 'ionicons/icons';
import MobileAppProfilePage from '../../pages/profile/MobileAppProfilePage';
import MobileAppDiscoverPage from '../../pages/discover/MobileAppDiscoverPage';
import MobileAppEventsPage from '../../pages/events/MobileAppEventsPage';
import MobileAppMaestrosPage from '../../pages/maestros/MobileAppMaestrosPage';
import MobileAppFriendsPage from '../../pages/friends/MobileAppFriendsPage';
import MobileAppNotificationsPage from '../../pages/notifications/MobileAppNotificationsPage';
import MobileAppResetPasswordPage from '../../pages/resetPassword/MobileAppResetPasswordPage';
import MobileAppResetPasswordSuccessPage from '../../pages/resetPasswordSuccess/MobileAppResetPasswordSuccessPage';
import { useTranslation } from 'react-i18next';

const MobileApp: React.FC = () => {
  const { t } = useTranslation();

  return (
    <IonTabs>
      {/* Tab transition animations do not exits by design */}
      <IonRouterOutlet animated={false}>
        <Route path="/reset-password/:token" render={() => <MobileAppResetPasswordPage />} exact={true} />
        <Route path="/reset-password-success" render={() => <MobileAppResetPasswordSuccessPage />} exact={true} />
        <Route path="/profile" render={() => <MobileAppProfilePage />} exact={true} />
        <Route path="/notifications" render={() => <MobileAppNotificationsPage />} exact={true} />
        <Route path="/discover" render={() => <MobileAppDiscoverPage />} exact={true} />
        <Route path="/events" render={() => <MobileAppEventsPage />} exact={true} />
        <Route path="/maestros" render={() => <MobileAppMaestrosPage />} exact={true} />
        <Route path="/friends" render={() => <MobileAppFriendsPage />} exact={true} />
        <Route path="/" render={() => <Redirect to="/discover" />} exact={true} />
      </IonRouterOutlet>
      <IonTabBar slot="bottom">
        <IonTabButton tab="discover" href="/discover">
          <IonIcon icon={homeOutline} />
          <IonLabel>{t('navigation.discover')}</IonLabel>
        </IonTabButton>
        <IonTabButton tab="events" href="/events">
          <IonIcon icon={calendarOutline} />
          <IonLabel>{t('navigation.events')}</IonLabel>
        </IonTabButton>
        <IonTabButton tab="maestros" href="/maestros">
          <IonIcon icon={peopleOutline} />
          <IonLabel>{t('navigation.maestros')}</IonLabel>
        </IonTabButton>
        <IonTabButton tab="friends" href="/friends">
          <IonIcon icon={peopleCircleOutline} />
          <IonLabel>{t('navigation.friends')}</IonLabel>
        </IonTabButton>
        <IonTabButton tab="profile" href="/profile">
          <IonIcon icon={personCircleOutline} />
          <IonLabel>{t('navigation.profile')}</IonLabel>
        </IonTabButton>
      </IonTabBar>
    </IonTabs>
  );
};

export default MobileApp;
