import React from 'react';
import MobileAppLayout from '../../components/layout/MobileAppLayout';
import { IonText } from '@ionic/react';
import { useTranslation } from 'react-i18next';

const MobileAppNotificationsPage: React.FC = () => {
  const { t } = useTranslation();

  return (
    <MobileAppLayout title={t('notifications_page.title')}>
      <IonText>{t('Notifications page')}</IonText>
    </MobileAppLayout>
  );
};

export default React.memo(MobileAppNotificationsPage);
