import React, { SyntheticEvent, useState } from 'react';
import { IonAvatar, IonImg, IonItem, IonPopover } from '@ionic/react';
import { Link, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useLogoutUser from '../../hooks/useLogoutUser';
import { useBreakpoint } from '../../context/breakpointContext';
import NotificationsNavigation from './NotificationsNavigation';
import avatar from '../../avatar.svg';
import './navigation.css';
import useIsMounted from 'ismounted';
import useCurrentUser from '../../hooks/useCurrentUser';

const ProfileNavigation: React.FC = () => {
  const isMounted = useIsMounted();
  const history = useHistory();
  const breakpoints = useBreakpoint();
  const isWidthLg = breakpoints['lg'];
  const { userFullName, userProfilePhoto } = useCurrentUser();
  const { logoutUser } = useLogoutUser();
  const { t } = useTranslation();

  const [showPopover, setShowPopover] = useState<{ open: boolean, event: Event | undefined }>({
    open: false,
    event: undefined,
  });

  const closePopover = () => {
    if (isMounted.current) {
      setShowPopover({open: false, event: undefined});
    }
  };

  const handleProfileLinkClick = (event: SyntheticEvent) => {
    event.preventDefault();
    if (isMounted.current) {
      setShowPopover({open: true, event: event.nativeEvent})
    }
  };

  const handleMyProfileItemClick = () => {
    closePopover();
    history.push('/profile');
  };

  const handleLogoutLinkClick = (event: SyntheticEvent) => {
    event.preventDefault();
    closePopover();
    logoutUser.mutate();
  };

  return (
    <>
      <NotificationsNavigation />
      <Link
        to="/"
        onClick={handleProfileLinkClick}
        className="ion-margin-end navigation-profile-link"
        data-cy="ProfileNavigation-Link-Profile"
      >
        {isWidthLg && <span className="navigation-user">{userFullName}</span>}
          <IonAvatar className="ion-margin-start navigation-avatar">
            <IonImg alt={userFullName} src={userProfilePhoto ? `${userProfilePhoto}?profile=thumb_28_28` : avatar} />
          </IonAvatar>
      </Link>
      <IonPopover
        isOpen={ showPopover.open }
        event={ showPopover.event }
        showBackdrop={false}
        onDidDismiss={() => closePopover()}
      >
        <IonItem lines="none" button={true} onClick={handleMyProfileItemClick} data-cy="ProfileNavigation-Link-MyProfile">
          {t('navigation.my_profile')}
        </IonItem>
        <IonItem lines="none" button={true} onClick={handleLogoutLinkClick} data-cy="ProfileNavigation-Link-LogOut">
          {t('navigation.log_out')}
        </IonItem>
      </IonPopover>
    </>
  );
};

export default ProfileNavigation;
