import openStreetLocation from '../model/openStreetLocation';
import { Location } from '../types/locationTypes';
import NetworkError from '../errors/NetworkError';
import { showToast } from '../actions/toastActions';
import { useDispatch } from 'react-redux';

const useOpenStreetLocation = (onSuccess: (locations: Location[] | []) => void, onError: (error: Error) => void) => {
  const dispatch = useDispatch();

  const searchLocations = async (query: string) => {
    let searchResults;
    try {
      searchResults = await openStreetLocation.searchCitiesAndTowns(query);
    } catch (error) {
      if (error instanceof NetworkError) {
        dispatch(showToast('Looks like you don\'t have a connection to the internet.', true));
      }
      onError(error);

      return;
    }

    if (!searchResults.hasOwnProperty('features')) {
      onSuccess([]);

      return;
    }

    let locations = searchResults.features;

    // Filter out results by "type" property
    // let locations = searchResults.features.filter((feature: any) => {
    //   return ['city', 'town', 'locality'].includes(feature.properties.type);
    // });

    // Generate name and osm type/id property
    locations = locations.map(
      (feature: any) => {
        const nameParts = [];
        nameParts.push(feature.properties.name);

        // City, town or village name
        if (feature.properties.city) {
          nameParts.push(feature.properties.city);
        } else if (feature.properties.town) {
          nameParts.push(feature.properties.city);
        } else if (feature.properties.village) {
          nameParts.push(feature.properties.village);
        }

        // State or country
        if (feature.properties.state) {
          nameParts.push(feature.properties.state);
        } else if (feature.properties.county) {
          nameParts.push(feature.properties.county);
        }

        // Country
        nameParts.push(feature.properties.country);

        return {
          osmShortTypeAndId: `${feature.properties.osm_type}${feature.properties.osm_id}`,
          name: nameParts.join(', ')
        }
      }
    );

    onSuccess(locations);
  };

  return { searchLocations };
};

export default useOpenStreetLocation;
