import React, { useState } from 'react';
import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonPage,
  IonToolbar
} from '@ionic/react';
import useLogoutUser from '../../hooks/useLogoutUser';
import { useTranslation } from 'react-i18next';
import Loader from '../../components/Loader';
import Toast from '../../components/toast/Toast';
import ProfileCompletionStepIndicator from "../../components/profileCompletion/ProfileCompletionStepIndicator";
import ProfileCompletionStep1 from "./ProfileCompletionStep1";
import ProfileCompletionStep2 from "./ProfileCompletionStep2";
import ProfileCompletionStep3 from "./ProfileCompletionStep3";
import { chevronBackCircleOutline } from "ionicons/icons";
import { useBreakpoint } from "../../context/breakpointContext";

const ProfileCompletionPage: React.FC = () => {
  const breakpoints = useBreakpoint();
  const { logoutUser } = useLogoutUser();
  const { t } = useTranslation();

  const [step, setStep] = useState<number>(1);

  const isWidthLg = breakpoints['lg'];
  const isToolbarBackButtonVisible = !isWidthLg && step > 1;

  const handleOnToolbarBackButtonClick = () => {
    setStep(step - 1);
  };

  const handleStep1Success = () => {
    setStep(2);
  };

  const handleStep2Success = () => {
    setStep(3);
  };

  const handleStep2BackButtonClick = () => {
    setStep(1);
  };

  const handleStep2SkipButtonClick = () => {
    setStep(3);
  };

  const handleStep3BackButtonClick = () => {
    setStep(2);
  };

  const handleOnLogoutButtonClick = () => {
    logoutUser.mutate();
  };

  const renderStepContent = (step: number): JSX.Element => {
    const steps: { [key: number]: JSX.Element } = {
      1: <ProfileCompletionStep1 onSuccess={handleStep1Success} />,
      2: <ProfileCompletionStep2 isBackButtonVisible={!isToolbarBackButtonVisible} onSuccess={handleStep2Success} onBackButtonClick={handleStep2BackButtonClick} onSkipButtonClick={handleStep2SkipButtonClick} />,
      3: <ProfileCompletionStep3 isBackButtonVisible={!isToolbarBackButtonVisible} onBackButtonClick={handleStep3BackButtonClick} />,
    };

    return steps[step] || null;
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          {isToolbarBackButtonVisible &&
            <IonButtons slot="start">
                <IonButton onClick={handleOnToolbarBackButtonClick}>
                    <IonIcon size="large" icon={chevronBackCircleOutline}/>
                </IonButton>
            </IonButtons>
          }
          <IonButtons slot="end">
            <IonButton onClick={handleOnLogoutButtonClick} data-cy="ProfileCompletionPage-Button-LogOut">{t('navigation.log_out')}</IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <ProfileCompletionStepIndicator currentStep={step} />
        {renderStepContent(step)}
      </IonContent>
      <Loader />
      <Toast />
    </IonPage>
  );
};

export default React.memo(ProfileCompletionPage);
