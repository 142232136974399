import { useMutation, useQueryClient } from 'react-query';
import userModel from '../model/user';
import ForbiddenError from '../errors/ForbiddenError';
import MissingFacebookScopeError from '../errors/MissingFacebookScopeError';
import NetworkError from '../errors/NetworkError';
import { showToast } from '../actions/toastActions';
import { useDispatch } from 'react-redux';

const useFacebookAuthentication = (onSuccess: () => void, onError: (error: Error) => void) => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const onAuthenticateFacebookUserSuccess = async () => {
    await queryClient.invalidateQueries('user');
    onSuccess();
  };

  const onAuthenticateFacebookUserError = async (error: Error) => {
    if (error instanceof ForbiddenError && error.data.missingScope) {
      const missingScope = error.data.missingScope;
      onError(new MissingFacebookScopeError('Missing scope', missingScope));
    } else {
      onError(error);
    }

    if (error instanceof NetworkError) {
      dispatch(showToast('Looks like you don\'t have a connection to the internet.', true));
    }
  };

  const authenticateFacebookUser = useMutation(
    userModel.authenticateFacebookUser, {
      onSuccess: onAuthenticateFacebookUserSuccess,
      onError: onAuthenticateFacebookUserError,
    }
  );

  return { authenticateFacebookUser };
};

export default useFacebookAuthentication;
