import React from 'react';
import { IonButtons, IonMenuButton, IonImg } from '@ionic/react';
import ionicLogo from '../../../ionicLogo.svg';
import SearchNavigation from '../../navigation/SearchNavigation';
import AuthenticationNavigation from '../../navigation/AuthenticationNavigation';
import ProfileNavigation from '../../navigation/ProfileNavigation';
import useCurrentUser from '../../../hooks/useCurrentUser';
import './mobileWebToolbar.css';

const MobileWebToolbar: React.FC = () => {
  const { isUserAuthenticated } = useCurrentUser();

  return (
    <>
      <IonButtons slot="start">
        <IonMenuButton data-cy="MainNavigation-Button-BurgerMenu" />
      </IonButtons>
      <IonImg slot="start" src={ionicLogo} className="navigation-logo" />
      <IonButtons slot="end">
        <SearchNavigation />
        {isUserAuthenticated
          ? <ProfileNavigation/>
          : <AuthenticationNavigation/>
        }
      </IonButtons>
    </>
  );
};

export default MobileWebToolbar;
