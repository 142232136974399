import React, { useState } from 'react';
import { IonButton, IonIcon, IonInput, IonItem, IonLabel, IonList, IonText } from '@ionic/react';
import { warningOutline } from 'ionicons/icons';
import { Controller, useForm } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { useDispatch } from 'react-redux';
import { hideLoader, showLoader } from '../../actions/loaderActions';
import ValidationError from '../../errors/ValidationError';
import { Violation } from '../../types/apiTypes';
import useResetPassword from '../../hooks/useResetPassword';
import { useTranslation } from 'react-i18next';
import config from '../../config/config';

interface Props {
  token: string;
  onSuccess: () => void;
}

interface FormData {
  password: string;
}

const ResetPasswordForm: React.FC<Props> = ({ token, onSuccess }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { control, errors, handleSubmit, setError } = useForm<FormData>();
  const [contextError, setContextError] = useState<string | null>(null);

  const onResetPasswordSuccess = () => {
    dispatch(hideLoader());
    onSuccess();
  };

  const onResetPasswordError = (error: Error) => {
    if (error instanceof ValidationError) {
      error.violations.forEach((violation: Violation) => {
        if (violation.propertyPath === 'token') {
          setContextError(t('error.link_for_resetting_password_has_expired'));
        } else {
          // @ts-ignore TS2345 Argument of type 'string' is not assignable to parameter of type '"password"'.
          setError(violation.propertyPath, { type: 'manual', message: violation.message });
        }
      });
    } else {
      setContextError(t('error.something_went_wrong'));
    }
    dispatch(hideLoader());
  };

  const { resetPassword } = useResetPassword(onResetPasswordSuccess, onResetPasswordError);

  const handleFormSubmit = async (data: FormData): Promise<void> => {
    setContextError(null);
    dispatch(showLoader());
    resetPassword.mutate({ token: token, password: data.password });
  };

  return (
    <form onSubmit={handleSubmit(handleFormSubmit)} noValidate={true}>
      <IonList>
        {contextError && (
          <IonItem lines="full">
            <IonIcon icon={warningOutline} color="danger" slot="start" />
            <IonText color="danger">{contextError}</IonText>
          </IonItem>
        )}
        <IonItem>
          <IonLabel position="floating" color="primary">{t('label.new_password')}</IonLabel>
          <Controller
            name="password"
            control={control}
            defaultValue={false}
            rules={{
              required: { value: true, message: t('validation.please_enter_your_password') },
              minLength: {
                value: config.userProfile.passwordMinLength,
                message: t('validation.password_should_have_at_least_characters', { count: config.userProfile.passwordMinLength })
              }
            }}
            render={({ value, onChange, onBlur }) => {
              return (
                <IonInput
                  type="password"
                  name="password"
                  clearOnEdit={true}
                  value={value}
                  required={true}
                  onIonChange={onChange}
                  onIonBlur={onBlur}
                  autofocus={true}
                  data-cy="ResetPasswordForm-Input-Password"
                />
              );
            }}
          />
        </IonItem>
        <ErrorMessage name="password" errors={errors} render={ ({ message }) => (
          <IonText color="danger">
            <p className="ion-padding-start ion-text-start">{message}</p>
          </IonText>
        )}/>
      </IonList>
      <IonButton type="submit" expand="full" size="default" color="primary" data-cy="ResetPasswordForm-Button-Submit">
        {t('button.set_new_password')}
      </IonButton>
    </form>
  );
};

export default ResetPasswordForm;
