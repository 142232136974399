import React from 'react';
import MobileAppLayout from '../../components/layout/MobileAppLayout';
import ResetPasswordContent from './ResetPasswordContent';
import { useTranslation } from 'react-i18next';

const MobileAppResetPasswordPage: React.FC = () => {
  const { t } = useTranslation();

  return (
    <MobileAppLayout title={t('reset_password_page.title')}>
      <ResetPasswordContent />
    </MobileAppLayout>
  );
};

export default React.memo(MobileAppResetPasswordPage);
