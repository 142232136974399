import React, { SyntheticEvent } from 'react';
import { Link } from 'react-router-dom';
import AuthModal from '../authModal/AuthModal';
import { useTranslation } from 'react-i18next';
import { useBreakpoint } from '../../context/breakpointContext';
import useAuthModal from '../../hooks/useAuthModal';

const AuthenticationNavigation: React.FC = () => {
  const breakpoints = useBreakpoint();
  const isWidthLg = breakpoints['lg'];
  const { authModalProps, openLoginModal, openRegistrationModal } = useAuthModal();
  const { t } = useTranslation();

  const handleRegisterLinkClick = (event: SyntheticEvent): void => {
    event.preventDefault();
    openRegistrationModal();
  };

  const handleLoginLinkClick = (event: SyntheticEvent): void => {
    event.preventDefault();
    openLoginModal();
  };

  return (
    <>
      {isWidthLg &&
        <Link
          to="/" onClick={handleRegisterLinkClick}
          className="ion-padding"
          data-cy="AuthenticationNavigation-Link-Register"
        >
          {t('navigation.register')}
        </Link>
      }
      <Link
        to="/"
        className="ion-padding"
        onClick={handleLoginLinkClick}
        data-cy="AuthenticationNavigation-Link-LogIn"
      >
        {t('navigation.log_in')}
      </Link>

      <AuthModal {...authModalProps} />
    </>
  );
};

export default AuthenticationNavigation;
