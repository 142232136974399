import { useMutation, useQueryClient } from 'react-query';
import userModel from '../model/user';
import { useDispatch } from 'react-redux';
import NetworkError from '../errors/NetworkError';
import { showToast } from '../actions/toastActions';

const useRegisterUser = (onRegisterUserSuccess: any, onRegisterUserError: any) => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const onSuccess = async () => {
    await queryClient.invalidateQueries('user');
    onRegisterUserSuccess();
  };

  const onError = (error: Error) => {
    if (error instanceof NetworkError) {
      dispatch(showToast('Looks like you don\'t have a connection to the internet.', true));
    }

    onRegisterUserError(error);
  };

  const registerUser = useMutation(
    userModel.registerUser, {
      onSuccess: onSuccess,
      onError: onError,
    }
  );

  return { registerUser };
};

export default useRegisterUser;
