import ValidationError from '../errors/ValidationError';
import UnauthorizedError from '../errors/UnauthorizedError';
import NetworkError from '../errors/NetworkError';
import UnexpectedResponseError from '../errors/UnexpectedResponseError';
import ForbiddenError from '../errors/ForbiddenError';
import config from '../config/config';

const apiFetch = async (url: string, method:string = 'GET', body?: BodyInit, headers?: {}): Promise<Response> => {
  try {
    return await fetch(
      `${config.apiUrl}${url}`,
      {
        credentials: 'include',
        method: method,
        headers: headers,
        body: body,
      }
    );
  } catch (error) {
    throw new NetworkError(error);
  }
};

export const apiRequest = async (url: string, method:string = 'GET', body?: BodyInit, headers?: {}): Promise<any> => {
  let response = await apiFetch(url, method, body, headers);

  // on 401 (Unauthorized) try authorizing with refresh token and retrying request
  if (response.status === 401) {
    const refreshTokenResponse = await apiFetch('/api/token/refresh');
    if (refreshTokenResponse.ok) {
      response = await apiFetch(url, method, body, headers);
    }
  }

  // on 400 (Bad Request) content SHOULD be JSON with violations
  if (response.status === 400) {
    const data = await response.json();
    if (data.violations) {
      throw new ValidationError('Bad api request', data.violations);
    } else {
      console.error(data);
      throw new Error('Bad api request');
    }
  }

  if (response.status === 401) {
    throw new UnauthorizedError('Unauthorized api request');
  }

  // on 403 (Forbidden) content COULD contain JSON with details
  if (response.status === 403) {
    let data;
    try {
      data = await response.json();
    } catch (error) {}
    throw new ForbiddenError('Forbidden api request', data);
  }

  // on 200 (Success)
  if (response.ok) {
    if (response.status === 204) {
      return;
    } else {
      return await response.json();
    }
  }

  // all other response statuses
  else {
    try {
      const data = await response.json();
      console.error(data);
    } catch (error) {}

    throw new UnexpectedResponseError('Unexpected api response', response);
  }
};
