import React, { SyntheticEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { IonAvatar, IonCol, IonGrid, IonRow } from '@ionic/react';
import { Link } from 'react-router-dom';
import avatar from '../../avatar.svg';
import useAuthModal from '../../hooks/useAuthModal';
import AuthModal from '../../components/authModal/AuthModal';
import './mobileAppAuthenticationContent.css';

const MobileAppAuthenticationContent: React.FC = () => {
  const { authModalProps, openLoginModal, openRegistrationModal } = useAuthModal();
  const { t } = useTranslation();

  const handleRegisterLinkClick = (event: SyntheticEvent): void => {
    event.preventDefault();
    openRegistrationModal();
  };

  const handleLoginLinkClick = (event: SyntheticEvent): void => {
    event.preventDefault();
    openLoginModal();
  };

  return(
    <>
      <IonGrid className="page-full-height">
        <IonRow className="page-full-height ion-justify-content-center">
          <IonCol className="ion-align-self-center ion-text-center">
            <div className="ion-padding">
              <IonAvatar className="mobile-app-authentication-page-avatar">
                <img alt="avatar" src={avatar} />
              </IonAvatar>
            </div>
            <div className="ion-padding-top">
              <Link to="/" onClick={handleLoginLinkClick}>{t('authentication_page.log_in')}</Link>
            </div>
            <div className="ion-padding-top ion-text-uppercase">
              - {t('_or')} -
            </div>
            <div className="ion-padding-top">
              <Link to="/" onClick={handleRegisterLinkClick}>{t('authentication_page.create_a_profile')}</Link>
            </div>
          </IonCol>
        </IonRow>
      </IonGrid>
      <AuthModal {...authModalProps} />
    </>
  );
};

export default MobileAppAuthenticationContent;
