// https://developer.mozilla.org/en-US/docs/Web/Media/Formats/Image_types

const fileTypesWithExtensions: { [key: string]: string } = {
  'image/apng': '.apng',
  'image/avif': '.avif',
  'image/gif': '.gif',
  'image/jpeg': '.jpg',
  'image/svg+xml': '.svg',
  'image/webp': '.webp',
};

const convertImageFileTypeToExtension = (fileType: string): string => {
  if (fileTypesWithExtensions.hasOwnProperty(fileType)) {
    return fileTypesWithExtensions[fileType];
  }

  return fileType.replace(/.+\//, '.');
};

export default { convertImageFileTypeToExtension };
