import React from 'react';
import MobileAppToolbar from '../toolbar/MobileAppToolbar';
import Layout from './Layout';

interface MobileAppLayoutProps {
  title: string;
}

const MobileAppLayout: React.FC<MobileAppLayoutProps> = ({ title, children }) => {
  return (
    <Layout toolbar={<MobileAppToolbar title={title} />}>
      {children}
    </Layout>
  );
};

export default MobileAppLayout;
