/**
 * @see https://medium.com/better-programming/how-to-use-media-queries-programmatically-in-react-4d6562c3bc97
 */
import React, { ReactNode, createContext, useContext, useEffect, useState } from 'react';

const defaultValue = {};

const BreakpointContext = createContext(defaultValue);

interface Props {
  children: ReactNode;
  queries: {[key: string]: string}
}

const BreakpointProvider = (props: Props) => {
  const { children, queries } = props;
  const [queryMatch, setQueryMatch] = useState<object>({});

  useEffect(() => {
    const mediaQueryLists: {[key: string]: any} = {};
    const queryKeys: string[] = Object.keys(queries);
    let isAttached: boolean = false;

    const handleQueryListener = () => {
      const updatedMatches = queryKeys.reduce((acc: {[key: string]: boolean}, media: string) => {
        acc[media] = !!(mediaQueryLists[media] && mediaQueryLists[media].matches);
        return acc;
      }, {});
      setQueryMatch(updatedMatches);
    };

    if (window && window.matchMedia) {
      const matches: {[key: string]: boolean} = {};
      queryKeys.forEach(media => {
        if (typeof queries[media] === 'string') {
          mediaQueryLists[media] = window.matchMedia(queries[media]);
          matches[media] = mediaQueryLists[media].matches;
        } else {
          matches[media] = false
        }
      });
      setQueryMatch(matches);
      isAttached = true;
      queryKeys.forEach(media => {
        if(typeof queries[media] === 'string') {
          mediaQueryLists[media].addListener(handleQueryListener)
        }
      });
    }

    return () => {
      if(isAttached) {
        queryKeys.forEach(media => {
          if(typeof queries[media] === 'string') {
            mediaQueryLists[media].removeListener(handleQueryListener)
          }
        });
      }
    }
  }, [queries]);

  return (
    <BreakpointContext.Provider value={queryMatch}>
      {children}
    </BreakpointContext.Provider>
  )
};

const useBreakpoint = (): {[key: string]: boolean} => {
  const context = useContext(BreakpointContext);
  if(context === defaultValue) {
    throw new Error('useBreakpoint must be used within BreakpointProvider');
  }

  return context;
};

export { useBreakpoint, BreakpointProvider };
