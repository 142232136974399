import { useState } from 'react';
import { AuthModalProps } from '../components/authModal/AuthModal';
import { AuthModalAction } from '../types/authModalTypes';
import useIsMounted from 'ismounted';

const useAuthModal = () => {
  const isMounted = useIsMounted();

  const [isAuthModalOpen, setIsAuthModalOpen] = useState<boolean>(false);
  const [authModalAction, setAuthModalAction] = useState<AuthModalAction>('register');

  const openLoginModal = () => {
    setAuthModalAction('login');
    setIsAuthModalOpen(true);
  };

  const openRegistrationModal = () => {
    setAuthModalAction('register');
    setIsAuthModalOpen(true);
  };

  const changeAction = (action: AuthModalAction) => {
    setAuthModalAction(action);
  };

  const close = () => {
    if (isMounted.current) {
      setIsAuthModalOpen(false);
    }
  };

  const authModalProps: AuthModalProps = {
    isOpen: isAuthModalOpen,
    action: authModalAction,
    onClose: close,
    onChangeAction: changeAction
  };

  return { authModalProps, openLoginModal, openRegistrationModal };
};

export default useAuthModal;
