import usePhotoFromDevice from "./usePhotoFromDevice";
import { CameraPhoto } from "@capacitor/core";
import useUploadProfilePhoto from "./useUploadProfilePhoto";
import dataUriToBlobConverter from '../utils/dataUriToBlobConverter';
import imageCompressor from '../utils/imageCompressor';
import config from '../config/config';
import UnsupportedImageTypeError from '../errors/UnsupportedImageTypeError';
import { useTranslation } from 'react-i18next';
import { hideLoader, showLoader } from '../actions/loaderActions';
import { useDispatch } from 'react-redux';
import imageTypeUtil from '../utils/imageTypeUtil';
import ExceededFileSizeError from '../errors/ExceededFileSizeError';

const useTakeProfilePhoto = (onSuccess: () => void, onError: (error: Error) => void) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const handleSuccess = () => {
    dispatch(hideLoader());
    onSuccess();
  };

  const handleError = (error: Error) => {
    dispatch(hideLoader());
    onError(error);
  };

  const { uploadProfilePhoto } = useUploadProfilePhoto(handleSuccess, handleError);

  const handleTakePhotoSuccess = async (cameraPhoto: CameraPhoto) => {
    dispatch(showLoader());

    let image: Blob;
    try {
      image = dataUriToBlobConverter.convertDataUriToBlob(cameraPhoto.dataUrl!);
    } catch (error) {
      console.error(error);
      handleError(error);

      return;
    }

    if (!config.userProfile.allowedProfilePhotoTypes.includes(image.type)) {
      handleError(new UnsupportedImageTypeError(
        t(
          'error.unsupported_image_type',
          {
            imageType: imageTypeUtil.convertImageFileTypeToExtension(image.type),
            allowedTypes: config.userProfile.allowedProfilePhotoTypes
              .map(imageType => imageTypeUtil.convertImageFileTypeToExtension(imageType))
              .join('", "')
          }
        )
      ));

      return;
    }

    let compressedImage;
    try {
      compressedImage = await imageCompressor.compressImage(image, {
        quality: config.userProfile.profilePhotoCompression.quality,
        maxWidth: config.userProfile.profilePhotoCompression.maxWidth,
        maxHeight: config.userProfile.profilePhotoCompression.maxHeight,
      });
    } catch (error) {
      console.error(error);
      handleError(error);

      return;
    }

    if (compressedImage.size > config.mediaObjectMaxUploadSize) {
      handleError(new ExceededFileSizeError(t('error.exceeded_image_file_size')));

      return;
    }

    await uploadProfilePhoto(compressedImage);
  };

  const { takePhoto } = usePhotoFromDevice(handleTakePhotoSuccess, handleError);

  const takeProfilePhoto = async () => {
    await takePhoto();  // take photo from device/desktop (from camera, gallery or file on desktop)
  }

  return { takeProfilePhoto };
};

export default useTakeProfilePhoto;
