import React from 'react';
import { IonToolbar } from '@ionic/react';
import { useBreakpoint } from '../../context/breakpointContext';
import DesktopWebToolbar from './webApp/DesktopWebToolbar';
import MobileWebToolbar from './webApp/MobileWebToolbar';

const WebAppToolbar: React.FC = () => {
  const breakpoints = useBreakpoint();
  const isWidthLg = breakpoints['lg'];

  return (
    <IonToolbar>
      {isWidthLg
        ? <DesktopWebToolbar />
        : <MobileWebToolbar />
      }
    </IonToolbar>
  );
};

export default WebAppToolbar;
