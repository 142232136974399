import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import config from '../config/config';
import enTranslation from './translations/en/translation.json';
import esTranslation from './translations/es/translation.json';
import hrTranslation from './translations/hr/translation.json';

i18next
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translation: enTranslation
      },
      es: {
        translation: esTranslation
      },
      hr: {
        translation: hrTranslation
      }
    },
    supportedLngs: ['en', 'pt', 'hr'],
    fallbackLng: 'en',  // use en if detected lng is not available
    ns: ["translation"], // 'common', 'moduleA', 'moduleB' ...
    defaultNS: "translation",
    saveMissing: false, // don't send non-translated keys to endpoint
    keySeparator: false, // we use content as keys
    interpolation: {
      escapeValue: false  // react already guards us from xss
    },
    debug: config.isDebug,
  });

export default i18next;
