import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IonLoading } from '@ionic/react';
import { hideLoader } from '../actions/loaderActions';
import { useTranslation } from 'react-i18next';

const Loader: React.FC = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const isLoaderVisible = useSelector((state: any) => state.loader.isLoaderVisible);

  return (
    <IonLoading
      isOpen={isLoaderVisible}
      message={t('loader.please_wait')}
      onDidDismiss={() => {dispatch(hideLoader())}}
      duration={30000}
    />
  );
};

export default Loader;
