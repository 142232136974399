class UnexpectedResponseError extends Error {
  readonly _response: Response;

  constructor(message: string, response: Response) {
    super(message);
    this.name = 'UnexpectedResponseError';
    this._response = response;
  }

  get response() {
    return this._response;
  }
}

export default UnexpectedResponseError;
