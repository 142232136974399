import React from 'react';
import MobileAppLayout from '../../components/layout/MobileAppLayout';
import ResetPasswordSuccessContent from './ResetPasswordSuccessContent';
import { useTranslation } from 'react-i18next';

const MobileAppResetPasswordSuccessPage: React.FC = () => {
  const { t } = useTranslation();

  return (
    <MobileAppLayout title={t('reset_password_success_page.title')}>
      <ResetPasswordSuccessContent />
    </MobileAppLayout>
  );
};

export default React.memo(MobileAppResetPasswordSuccessPage);
