import React from 'react';
import {
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonCol,
  IonGrid,
  IonRow,
} from '@ionic/react';
import { useTranslation } from "react-i18next";
import ProfileCompletionStep2Form from "../../components/forms/ProfileCompletionStep2Form";

interface Props {
  isBackButtonVisible: boolean;
  onSuccess: () => void;
  onBackButtonClick: () => void;
  onSkipButtonClick: () => void;
}

const ProfileCompletionStep2: React.FC<Props> = ({ isBackButtonVisible, onSuccess, onBackButtonClick, onSkipButtonClick }) => {
  const { t } = useTranslation();

  return (
    <IonGrid>
      <IonRow className="ion-justify-content-center">
        <IonCol sizeSm="8" sizeMd="7" sizeLg="6" sizeXl="5">
          <IonCard>
            <>
              <IonCardHeader>
                <IonCardTitle>{t('profile_completion_step_2_title')}</IonCardTitle>
              </IonCardHeader>
              <IonCardContent>
                <ProfileCompletionStep2Form
                  isBackButtonVisible={isBackButtonVisible}
                  onSuccess={onSuccess}
                  onBackButtonClick={onBackButtonClick}
                  onSkipButtonClick={onSkipButtonClick} />
              </IonCardContent>
            </>
          </IonCard>
        </IonCol>
      </IonRow>
    </IonGrid>
  );
};

export default ProfileCompletionStep2;
