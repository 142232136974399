import { SHOW_LOADER, HIDE_LOADER } from '../actions/loaderActions';
import { createReducer } from '@reduxjs/toolkit';
import { LoaderState } from '../types/loaderTypes';

const initialState: LoaderState = {
  isLoaderVisible: false,
};

const loaderReducer: any = createReducer(initialState, {
  [SHOW_LOADER]: (state: LoaderState = initialState) => {
    state.isLoaderVisible = true;
  },
  [HIDE_LOADER]: (state: LoaderState = initialState) => {
    state.isLoaderVisible = false;
  },
});

export default loaderReducer;
