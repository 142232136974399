class MissingFacebookScopeError extends Error {
  readonly _scope: string;

  constructor(message: string, scope: string) {
    super(message);
    this.name = 'MissingFacebookScopeError';
    this._scope = scope;
  }

  get scope() {
    return this._scope;
  }
}

export default MissingFacebookScopeError;
