import { useCamera } from '@capacitor-community/react-hooks/camera';
import { CameraDirection, CameraPhoto, CameraResultType, CameraSource } from "@capacitor/core";
import { useTranslation } from 'react-i18next';
import CameraAccessDeniedError from '../errors/CameraAccessDeniedError';

const usePhotoFromDevice = (onSuccess: (cameraPhoto: CameraPhoto) => void, onError: (error: Error) => void) => {
  const { getPhoto } = useCamera();
  const { t } = useTranslation();

  const takePhoto = async () => {
    try {
      const cameraPhoto = await getPhoto({
        resultType: CameraResultType.DataUrl,
        source: CameraSource.Prompt,
        quality: 100,
        allowEditing: true,
        saveToGallery: false,
        direction: CameraDirection.Front,
        promptLabelHeader: t('take_photo_widget.label.header'),  // iOS only
        promptLabelCancel: t('take_photo_widget.label.cancel'), // iOS only
        promptLabelPhoto: t('take_photo_widget.label.photo'),
        promptLabelPicture: t('take_photo_widget.label.picture'),
      });

      onSuccess(cameraPhoto);
    } catch (error) {
      console.error(error);
      if (error.message === 'User cancelled photos app') {  // camera plugin returns errors as strings
        // do nothing
      } else if (error.message === 'No image picked') {
        // do nothing
      } else if (error.message === 'Unable to access camera, user denied permission request') {
        onError(new CameraAccessDeniedError(t('take_photo_widget.error.camera_access_denied')));
      } else {
        onError(error);
      }
    }
  };

  return { takePhoto };
};

export default usePhotoFromDevice;
