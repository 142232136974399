import { useMutation } from 'react-query';
import userModel from '../model/user';
import { useDispatch } from 'react-redux';
import NetworkError from '../errors/NetworkError';
import { showToast } from '../actions/toastActions';

const useResetPassword = (onResetPasswordSuccess: any, onResetPasswordError: any) => {
  const dispatch = useDispatch();

  const onError = (error: Error) => {
    if (error instanceof NetworkError) {
      dispatch(showToast('Looks like you don\'t have a connection to the internet.', true));
    }

    onResetPasswordError();
  };

  const resetPassword = useMutation(
    userModel.resetPassword, {
      onSuccess: onResetPasswordSuccess,
      onError: onError,
    }
  );

  return { resetPassword };
  };

export default useResetPassword;
