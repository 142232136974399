import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import { IonButtons } from '@ionic/react';
import './navigation.css';

interface NavigationItem {
  title: string;
  path: string;
  isVisible: boolean;
  onClick?: (e: React.MouseEvent<HTMLAnchorElement>) => void;
  dataCy?: string;
}

const MainNavigation: React.FC = () => {
  const location = useLocation();
  const { t } = useTranslation();

  const mainNavigationItems: NavigationItem[] = [
    { title: t('navigation.discover'), path: '/discover', isVisible: true },
    { title: t('navigation.events'), path: '/events', isVisible: true },
    { title: t('navigation.maestros'), path: '/maestros', isVisible: true },
    { title: t('navigation.friends'), path: '/friends', isVisible: true }
  ];

  const renderNavigationItems = (navigationItems: NavigationItem[]) => {
    return navigationItems
      .map(navigationItem => {
        return navigationItem.isVisible
          ? (
            <Link
              key={navigationItem.title}
              className={`ion-padding ${location.pathname.startsWith(navigationItem.path) ? 'navigation-item-selected' : ''}`}
              to={navigationItem.path}
              onClick={navigationItem.onClick}
              data-cy={navigationItem.dataCy}
            >
              {navigationItem.title}
            </Link>
          )
          : null;
      });
  };

  return (
    <IonButtons slot="start" data-cy="MainNavigation">
      {renderNavigationItems(mainNavigationItems)}
    </IonButtons>
  );
};

export default MainNavigation;
