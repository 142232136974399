import { useMutation } from 'react-query';
import userModel from '../model/user';
import NetworkError from '../errors/NetworkError';
import { showToast } from '../actions/toastActions';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

const useForgotPassword = (onForgotPasswordSuccess: any, onForgotPasswordError: any) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const onError = (error: Error) => {
    if (error instanceof NetworkError) {
      dispatch(showToast(t('error.no_internet_connection'), true));
    }

    onForgotPasswordError();
  };

  const forgotPassword = useMutation(
    userModel.forgotPassword, {
      onSuccess: onForgotPasswordSuccess,
      onError: onError,
    }
  );

  return { forgotPassword };
};

export default useForgotPassword;
