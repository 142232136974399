import { useMutation, useQueryClient } from 'react-query';
import userModel from '../model/user';
import { useDispatch } from 'react-redux';
import NetworkError from '../errors/NetworkError';
import { showToast } from '../actions/toastActions';

const useFlagProfileAsComplete = (onError: (error: Error) => void) => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const onFlagProfileAsCompleteSuccess = async () => {
    await queryClient.invalidateQueries('user');
  };

  const onFlagProfileAsCompleteError = (error: Error) => {
    if (error instanceof NetworkError) {
      dispatch(showToast('Looks like you don\'t have a connection to the internet.', true));
    }

    onError(error);
  };

  const flagProfileAsComplete = useMutation(
    userModel.flagProfileAsComplete, {
      onSuccess: onFlagProfileAsCompleteSuccess,
      onError: onFlagProfileAsCompleteError,
    }
  );

  return { flagProfileAsComplete };
};

export default useFlagProfileAsComplete;
