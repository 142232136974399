import React from 'react';
import MainNavigation from '../../navigation/MainNavigation';
import { IonButtons } from '@ionic/react';
import SearchNavigation from '../../navigation/SearchNavigation';
import ProfileNavigation from '../../navigation/ProfileNavigation';
import AuthenticationNavigation from '../../navigation/AuthenticationNavigation';
import useCurrentUser from '../../../hooks/useCurrentUser';

const DesktopWebToolbar: React.FC = () => {
  const { isUserAuthenticated } = useCurrentUser();

  return (
    <>
      <IonButtons slot="start">
        <MainNavigation />
      </IonButtons>
      <IonButtons slot="end">
        <SearchNavigation />
        {isUserAuthenticated
          ? <ProfileNavigation/>
          : <AuthenticationNavigation/>
        }
      </IonButtons>
    </>
  );
};

export default DesktopWebToolbar;
