import React from 'react';
import WebAppLayout from '../../components/layout/WebAppLayout';
import ResetPasswordSuccessContent from './ResetPasswordSuccessContent';

const WebAppResetPasswordSuccessPage: React.FC = () => {
  return (
    <WebAppLayout>
      <ResetPasswordSuccessContent />
    </WebAppLayout>
  );
};

export default React.memo(WebAppResetPasswordSuccessPage);
