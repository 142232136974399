import React from 'react';
import { IonChip, IonLabel } from '@ionic/react';
import './profileCompletionStepIndicator.css';

interface Props {
  currentStep: number;
}

const ProfileCompletionStepIndicator: React.FC<Props> = ({ currentStep }) => {
  const renderSteps = () => {
    const steps = [1, 2, 3];

    return steps.map(step => {
      return (
        <IonChip key={step} color={getBgColorForStep(step)} className="step-indicator-chip">
          <IonLabel color={getLabelColorForStep(step)}>{step}</IonLabel>
        </IonChip>
      );
    });
  };

  const getLabelColorForStep = (step: number) => {
    return step === currentStep ? 'primary' : 'secondary';
  };

  const getBgColorForStep = (step: number) => {
    return step === currentStep ? 'primary' : 'dark';
  };

  return (
    <div className="ion-margin ion-text-center">
      {renderSteps()}
    </div>
  );
};

export default ProfileCompletionStepIndicator;
