import React, { useState } from 'react';
import {
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonRow,
  IonToolbar
} from '@ionic/react';
import { chevronBackCircleOutline } from 'ionicons/icons';
import ForgotPasswordForm from '../forms/ForgotPasswordForm';
import { Trans, useTranslation } from 'react-i18next';

interface Props {
  onBackButtonClick: () => void;
  onCloseButtonClick: () => void;
}

const ModalContentForgotPassword: React.FC<Props> = ({ onBackButtonClick, onCloseButtonClick }) => {
  const { t } = useTranslation();
  const [isSubmittedSuccessfully, setIsSubmittedSuccessfully] = useState<boolean>(false);

  const handleBackButtonClick = () => {
    onBackButtonClick();
  };

  const handleCloseButtonClick = () => {
    onCloseButtonClick();
  };

  const handleFormSuccess = () => {
    setIsSubmittedSuccessfully(true);
  };

  return (
    <>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            {!isSubmittedSuccessfully &&
              <IonButton onClick={handleBackButtonClick}>
                <IonIcon size="large" icon={chevronBackCircleOutline} />
              </IonButton>
            }
          </IonButtons>
          <IonButtons slot="end">
            <IonButton onClick={() => handleCloseButtonClick()}>{t('modal.close')}</IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonGrid>
          <IonRow className="ion-justify-content-center">
            <IonCol sizeSm="10">
              <IonCard>
                <IonCardHeader>
                  <IonCardTitle className="ion-text-center"><h1>{t('password_recovery_page.title')}</h1></IonCardTitle>
                </IonCardHeader>
                <IonCardContent className="ion-text-center">
                  {!isSubmittedSuccessfully &&
                    <>
                      <div className="ion-padding-top ion-text-left">
                        {t('password_recovery_page.instruction_message')}
                      </div>
                      <ForgotPasswordForm onSuccess={handleFormSuccess}/>
                    </>
                  }
                  {isSubmittedSuccessfully &&
                    <>
                      <div className="ion-padding-top ion-text-left" data-cy="ModalContentForgotPassword-SuccessMessage">
                        <Trans
                          i18nKey="password_recovery_page.success_message"
                          components={{ P: <p/> }}
                        />
                      </div>
                    </>
                  }
                </IonCardContent>
              </IonCard>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </>
  );
};

export default ModalContentForgotPassword;
