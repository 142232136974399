import React from 'react';
import {
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonRow,
  IonToolbar,
} from '@ionic/react';
import { chevronBackCircleOutline } from 'ionicons/icons';
import RegisterForm from '../forms/RegisterForm';
import { useTranslation } from 'react-i18next';

interface Props {
  onBackButtonClick: () => void;
  onCloseButtonClick: () => void;
  onSuccess: () => void;
}

const ModalContentRegisterClassic: React.FC<Props> = ({ onBackButtonClick, onCloseButtonClick, onSuccess }) => {
  const { t } = useTranslation();

  const handleBackButtonClick = () => {
    onBackButtonClick();
  };

  const handleCloseButtonClick = () => {
    onCloseButtonClick();
  };

  const handleFormSuccess = () => {
    onSuccess();
  };

  return (
    <>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonButton onClick={handleBackButtonClick}>
              <IonIcon size="large" icon={chevronBackCircleOutline} />
            </IonButton>
          </IonButtons>
          <IonButtons slot="end">
            <IonButton onClick={handleCloseButtonClick}>{t('modal.close')}</IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonGrid>
          <IonRow className="ion-justify-content-center">
            <IonCol sizeSm="10">
              <IonCard>
                <IonCardHeader>
                  <IonCardTitle className="ion-text-center"><h1>{t('classic_registration_page.title')}</h1></IonCardTitle>
                  <IonCardSubtitle className="ion-text-center">{t('_with_email')}</IonCardSubtitle>
                </IonCardHeader>
                <IonCardContent className="ion-text-center">
                  <RegisterForm onSuccess={handleFormSuccess} />
                </IonCardContent>
              </IonCard>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </>
  );
};

export default ModalContentRegisterClassic;
