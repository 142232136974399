import config from '../config/config';
import NetworkError from '../errors/NetworkError';
import UnexpectedResponseError from '../errors/UnexpectedResponseError';

const apiFetch = async (url: string, method:string = 'GET', body?: BodyInit, headers?: {}): Promise<Response> => {
  try {
    return await fetch(
      `${config.photonApiUrl}${url}`,
      {
        method: method,
        headers: headers,
        body: body,
      }
    );
  } catch (error) {
    throw new NetworkError(error);
  }
};

export const apiRequest = async (url: string, method:string = 'GET', body?: BodyInit, headers?: {}): Promise<any> => {
  let response = await apiFetch(url, method, body, headers);

  if (response.status === 200) {
    return await response.json();
  } else {
    try {
      const data = await response.json();
      console.error(data);
    } catch (error) {
      console.error(error);
    }

    throw new UnexpectedResponseError('Unexpected Photon api response', response);
  }
};
