import { useMutation, useQueryClient } from 'react-query';
import userModel from '../model/user';
import NetworkError from '../errors/NetworkError';
import { showToast } from '../actions/toastActions';
import { useDispatch } from 'react-redux';
import { Plugins } from '@capacitor/core';
import { hideLoader, showLoader } from '../actions/loaderActions';

const useLogoutUser = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const onMutate = () => {
    dispatch(showLoader());
  };

  const onSuccess = async () => {
    await queryClient.invalidateQueries('user');
    await logoutFromFacebook();
  };

  const logoutFromFacebook = async () => {
    const { FacebookLogin } = Plugins;
    const accessToken = await FacebookLogin.getCurrentAccessToken();
    if (accessToken) {
      try {
        await FacebookLogin.logout();
      } catch (error) {
        console.error(error);
      }
    }
  };

  const onError = (error: Error) => {
    if (error instanceof NetworkError) {
      dispatch(showToast('Looks like you don\'t have a connection to the internet.', true));
    }

    console.error(error);
  };

  const onSettled = () => {
    dispatch(hideLoader());
  };

  const logoutUser = useMutation(
    userModel.logoutUser, {
      onMutate: onMutate,
      onSuccess: onSuccess,
      onError: onError,
      onSettled: onSettled
    }
  );

  return { logoutUser };
};

export default useLogoutUser;
