import React, { useState } from 'react';
import {
  IonButton,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonSelect,
  IonSelectOption,
  IonText,
} from '@ionic/react';
import { Controller, useForm } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { useDispatch } from 'react-redux';
import { hideLoader, showLoader } from '../../actions/loaderActions';
import { warningOutline } from 'ionicons/icons';
import ValidationError from '../../errors/ValidationError';
import { Violation } from '../../types/apiTypes';
import { useTranslation } from 'react-i18next';
import useUpdateUser from '../../hooks/useUpdateUser';
import useCurrentUser from '../../hooks/useCurrentUser';
import config from '../../config/config';
import usePlatform from '../../hooks/usePlatform';
import SelectLocationWidget from './SelectLocationWidget';
import './profileCompletionForm.css';

interface Props {
  onSuccess: () => void;
}

interface FormData {
  firstName: string;
  lastName: string;
  currentCity: string | boolean;  // optional field, results in "false" when empty
  dancerRole: string;
}

const ProfileCompletionStep1Form: React.FC<Props> = ({ onSuccess }) => {
  const { isIosApp } = usePlatform();
  const dispatch = useDispatch();
  const { userData } = useCurrentUser();
  const { t } = useTranslation();
  const { control, errors, handleSubmit, setError } = useForm<FormData>({
    defaultValues: {
      firstName: userData!.firstName,
      lastName: userData!.lastName,
      currentCity: userData!.currentCity ? userData!.currentCity['@id'] : false,
      dancerRole: userData!.dancerRole
    }
  });
  const [contextError, setContextError] = useState<string | null>(null);

  const onUpdateUserSuccess = () => {
    dispatch(hideLoader());
    onSuccess();
  };

  const onUpdateUserError = (error: Error) => {
    if (error instanceof ValidationError) {
      error.violations.forEach((violation: Violation) => {
        // @ts-ignore TS2345 Argument of type 'string' is not assignable to parameter of type ...
        setError(violation.propertyPath, { type: 'manual', message: violation.message });
      });
    } else {
      setContextError(t('error.something_went_wrong'));
    }
  };

  const { updateUser } = useUpdateUser(onUpdateUserSuccess, onUpdateUserError);

  const handleFormSubmit = (data: FormData): void => {
    dispatch(showLoader());
    setContextError(null);

    updateUser.mutate({
      userId: userData!.id,
      firstName: data.firstName,
      lastName: data.lastName,
      currentCity: typeof data.currentCity === 'string' ? data.currentCity : undefined,
      dancerRole: data.dancerRole,
    });
  };

  return (
    <form onSubmit={handleSubmit(handleFormSubmit)} noValidate={true}>
      <IonList>
        {contextError && (
          <IonItem lines="full">
            <IonIcon icon={warningOutline} color="danger" slot="start" />
            <IonText color="danger">{contextError}</IonText>
          </IonItem>
        )}

        <IonItem>
          <IonLabel position="floating" color="primary">{t('label.first_name')}</IonLabel>
          <Controller
            name="firstName"
            control={control}
            defaultValue={false}
            rules={{
              required: { value: true, message: t('validation.please_enter_your_first_name') },
            }}
            render={({ name, value, onChange, onBlur }) => {
              return (
                <IonInput
                  type="text"
                  inputmode="text"
                  name={name}
                  value={value}
                  required={true}
                  maxlength={config.userProfile.firstNameMaxLength}
                  onIonChange={onChange}
                  onIonBlur={onBlur}
                  autofocus={true}
                  data-cy="ProfileCompletionStep1Form-Input-FirstName"
                />
              );
            }}
          />
        </IonItem>
        <ErrorMessage name="firstName" errors={errors} render={ ({ message }) => (
          <IonText color="danger" data-cy="ProfileCompletionStep1Form-ValidationError-FirstName">
            <p className="ion-padding-start ion-text-start">{message}</p>
          </IonText>
        )}/>

        <IonItem>
          <IonLabel position="floating" color="primary">{t('label.last_name')}</IonLabel>
          <Controller
            name="lastName"
            control={control}
            defaultValue={false}
            rules={{
              required: { value: true, message: t('validation.please_enter_your_last_name') },
            }}
            render={({ name, value, onChange, onBlur }) => {
              return (
                <IonInput
                  type="text"
                  inputmode="text"
                  name={name}
                  value={value}
                  required={true}
                  maxlength={config.userProfile.lastNameMaxLength}
                  onIonChange={onChange}
                  onIonBlur={onBlur}
                  data-cy="ProfileCompletionStep1Form-Input-LastName"
                />
              );
            }}
          />
        </IonItem>
        <ErrorMessage name="lastName" errors={errors} render={ ({ message }) => (
          <IonText color="danger" data-cy="ProfileCompletionStep1Form-ValidationError-LastName">
            <p className="ion-padding-start ion-text-start">{message}</p>
          </IonText>
        )}/>

        <IonItem>
          <IonLabel position="floating" color="primary">{t('label.current_city')}</IonLabel>
          <Controller
            name="currentCity"
            control={control}
            defaultValue={false}
            render={({ name, value, onChange, onBlur }) => {
              return (
                <SelectLocationWidget
                  name={name}
                  value={value}
                  displayName={userData!.currentCity ? userData!.currentCity.displayName : ''}
                  onChange={onChange}
                  onBlur={onBlur}
                />
              );
            }}
          />
        </IonItem>
        <ErrorMessage name="currentCity" errors={errors} render={ ({ message }) => (
          <IonText color="danger" data-cy="ProfileCompletionStep1Form-ValidationError-CurrentCity">
            <p className="ion-padding-start ion-text-start">{message}</p>
          </IonText>
        )}/>

        <IonItem>
          <IonLabel position="floating" color="primary">{t('label.dancer_role')}</IonLabel>
          <Controller
            name="dancerRole"
            control={control}
            defaultValue={false}
            rules={{
              required: { value: true, message: t('validation.please_select_your_dancer_role') },
            }}
            render={({ name, value, onChange, onBlur }) => {
              return (
                <IonSelect
                  name={name}
                  value={value}
                  onIonChange={onChange}
                  onIonBlur={onBlur}
                  className="select-popover"
                  interface={isIosApp ? "action-sheet" : "popover"}
                  okText={t('label.ok')}
                  cancelText={t('label.cancel')}
                  data-cy="ProfileCompletionStep1Form-Input-DancerRole"
                >
                  <IonSelectOption value="leader">{t('option.dancer_role.leader')}</IonSelectOption>
                  <IonSelectOption value="follower">{t('option.dancer_role.follower')}</IonSelectOption>
                  <IonSelectOption value="both">{t('option.dancer_role.both')}</IonSelectOption>
                </IonSelect>
              );
            }}
          />
        </IonItem>
        <ErrorMessage name="dancerRole" errors={errors} render={ ({ message }) => (
          <IonText color="danger" data-cy="ProfileCompletionStep1Form-ValidationError-DancerRole">
            <p className="ion-padding-start ion-text-start">{message}</p>
          </IonText>
        )}/>
      </IonList>

      <div className="form-footer">
        <div className="form-footer__buttons-right">
          <IonButton
            type="submit"
            size="default"
            color="primary"
            data-cy="ProfileCompletionStep1Form-Button-Continue"
          >
            {t('button.continue')}
          </IonButton>
        </div>
        <div className="form-footer__clear-both" />
      </div>
    </form>
  );
};

export default ProfileCompletionStep1Form;
