import { SHOW_TOAST, HIDE_TOAST } from '../actions/toastActions';
import { createReducer } from '@reduxjs/toolkit';
import { ToastState } from '../types/toastTypes';

const initialState: ToastState = {
  isToastVisible: false,
  toastMessage: '',
  isDismissible: false
};

const toastReducer: any = createReducer(initialState, {
  [SHOW_TOAST]: (state: ToastState = initialState, action: any) => {
    state.isToastVisible = true;
    state.toastMessage = action.message;
    state.isDismissible = action.isDismissible;
  },
  [HIDE_TOAST]: (state: ToastState = initialState, action: any) => {
    state.isToastVisible = false;
    state.toastMessage = '';
  },
});

export default toastReducer;
