import React from 'react';
import { IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonCol, IonGrid, IonRow } from '@ionic/react';
import { useTranslation, Trans } from 'react-i18next';

const ResetPasswordSuccessContent: React.FC = () => {
  const { t } = useTranslation();

  return (
    <IonGrid className="layout-full-height">
      <IonRow className="layout-full-height ion-justify-content-center">
        <IonCol size-md="6" size-sm="8" size-xs="12" className="ion-align-self-center">
          <IonCard>
            <IonCardHeader>
              <IonCardTitle>{t('reset_password_success_page.title')}</IonCardTitle>
            </IonCardHeader>
            <IonCardContent>
              <div className="ion-padding-top ion-text-left" data-cy="ResetPasswordSuccessPage-SuccessMessage">
                <Trans
                  i18nKey="reset_password_success_page.success_message"
                  components={{ P: <p/> }}
                />
              </div>
            </IonCardContent>
          </IonCard>
        </IonCol>
      </IonRow>
    </IonGrid>
  );
};

export default ResetPasswordSuccessContent;
