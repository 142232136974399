import React from 'react';
import {
  IonAvatar,
  IonButton,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonItem
} from '@ionic/react';
import avatar from '../../avatar.svg';
import './mobileAppProfileContent.css';
import useLogoutUser from '../../hooks/useLogoutUser';
import { useTranslation } from 'react-i18next';

const MobileAppProfileContent: React.FC = () => {
  const { logoutUser } = useLogoutUser();
  const { t } = useTranslation();

  const handleOnLogoutButtonClick = () => {
    logoutUser.mutate();
  };

  return(
    <>
      <IonItem lines="none" className="ion-padding">
        <IonAvatar slot="start" className="mobile-app-profile-content-avatar">
          <img alt="avatar" src={avatar} />
        </IonAvatar>
        <IonCard>
          <IonCardHeader>
            <IonCardTitle>John Doe</IonCardTitle>
          </IonCardHeader>
          <IonCardContent>
            I'm from ...
          </IonCardContent>
        </IonCard>
      </IonItem>
      <div className="ion-padding">
        <IonButton onClick={handleOnLogoutButtonClick}>{t('navigation.log_out')}</IonButton>
      </div>
    </>
  );
};

export default MobileAppProfileContent;
