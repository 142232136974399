import React from 'react';
import MobileAppLayout from '../../components/layout/MobileAppLayout';
import { IonText } from '@ionic/react';
import { useTranslation } from 'react-i18next';

const MobileAppMaestrosPage: React.FC = () => {
  const { t } = useTranslation();

  return (
    <MobileAppLayout title={t('maestros_page.title')}>
      <IonText>{t('Maestros Page')}</IonText>
    </MobileAppLayout>
  );
};

export default React.memo(MobileAppMaestrosPage);
