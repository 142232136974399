import React from 'react';
import { IonText } from '@ionic/react';
import WebAppLayout from '../../components/layout/WebAppLayout';
import { useTranslation } from 'react-i18next';

const WebAppEventsPage: React.FC = () => {
  const { t } = useTranslation();

  return (
    <WebAppLayout>
      <IonText>{t('Events Page')}</IonText>
    </WebAppLayout>
  );
};

export default React.memo(WebAppEventsPage);
