import React, { useEffect, useState } from 'react';
import SelectAutocompleteWidget, { SearchResultItem } from './SelectAutocompleteWidget';
import useOpenStreetLocation from '../../hooks/useOpenStreetLocation';
import { Location, SaveLocationResponse } from '../../types/locationTypes';
import useSaveLocation from '../../hooks/useSaveLocation';
import { useDispatch } from 'react-redux';
import { hideLoader, showLoader } from '../../actions/loaderActions';

interface Props {
  name: string;
  value: string;
  displayName: string;
  onChange: (...event: any[]) => void;
  onBlur: () => void;
}

const SelectLocationWidget: React.FC<Props> = ({ name, value, displayName, onChange, onBlur }) => {
  const dispatch = useDispatch();

  const [displayValue, setDisplayValue] = useState<string>('');
  const [isSearching, setIsSearching] = useState<boolean>(false);
  const [searchResultItems, setSearchResultItems] = useState<SearchResultItem[]>([]);

  const handleGetLocationsSuccess = (locations: Location[]) => {
    const searchResultItems = locations.map((location: Location) => {
      return {
        value: location.osmShortTypeAndId,
        label: location.name,
      }
    });

    setSearchResultItems(searchResultItems);
  };

  const handleGetLocationsError = (error: Error) => {
    console.error(error);
    setSearchResultItems([]);
  };

  const { searchLocations } = useOpenStreetLocation(handleGetLocationsSuccess, handleGetLocationsError);

  const handleSearchValueIsEntered = async (query: string) => {
    if (!query) {
      setSearchResultItems([]);

      return;
    }

    setIsSearching(true);
    await searchLocations(query);
    setIsSearching(false);
  };

  const onSaveLocationSuccess = (saveLocationResponse: SaveLocationResponse) => {
    // Manually trigger onChange event for the Form component
    // because hidden input element does not emmit onChange event in React
    onChange(saveLocationResponse['@id']);

    setDisplayValue(saveLocationResponse.displayName);
  };

  const onSaveLocationError = () => {
    // Todo: For the moment the previous value will stay unchanged if new one can not be saved
  };

  const { saveLocation } = useSaveLocation(onSaveLocationSuccess, onSaveLocationError);

  const handleItemIsChosen = async (searchResultItem: SearchResultItem) => {
    dispatch(showLoader());
    await saveLocation(searchResultItem.value);
    dispatch(hideLoader());
    setSearchResultItems([]);
  };

  useEffect(() => {
    setDisplayValue(displayName);
  }, [displayName]);

  return (
    <SelectAutocompleteWidget
      name={name}
      value={value}
      displayValue={displayValue}
      onSearchValueIsEntered={handleSearchValueIsEntered}
      onItemIsChosen={handleItemIsChosen}
      isSearching={isSearching}
      searchResultItems={searchResultItems}
    />
  );
};

export default SelectLocationWidget;
