import React, { SyntheticEvent } from 'react';
import { IonIcon } from '@ionic/react';
import { searchOutline } from 'ionicons/icons';
import { Link } from 'react-router-dom';
import './navigation.css';

const SearchNavigation: React.FC = () => {

  const handleOnSearchLinkClick = (event: SyntheticEvent) => {
    event.preventDefault();
    // Todo: implement
    console.log('search');
  };

  return (
    <Link
      to="/"
      onClick={handleOnSearchLinkClick}
      className="ion-padding"
      data-cy="SearchNavigation-Link-Search"
    >
      <IonIcon icon={searchOutline} className="navigation-icon" />
    </Link>
  );
};

export default SearchNavigation;
