import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IonToast } from '@ionic/react';
import { hideToast } from '../../actions/toastActions';
import usePlatform from '../../hooks/usePlatform';

const Toast: React.FC = () => {
  const dispatch = useDispatch();
  const { isMobileApp } = usePlatform();

  const isToastVisible = useSelector((state: any) => state.toast.isToastVisible);
  const toastMessage = useSelector((state: any) => state.toast.toastMessage);
  const isDismissible = useSelector((state: any) => state.toast.isDismissible);
  const defaultToastDurationMs = 3000;

  const onToastDidDismiss = () => {
    dispatch(hideToast());
  };

  return (
    <IonToast
      isOpen={isToastVisible}
      message={toastMessage}
      position={isMobileApp ? 'bottom' : 'top'}
      buttons={isDismissible
        ?
          [
            {
              text: 'Hide',
              role: 'cancel',
            }
          ]
        : []
      }
      duration={defaultToastDurationMs}
      onDidDismiss={onToastDidDismiss}
      data-cy="toast"
    />
  );
};

export default Toast;
