import React, { useState } from 'react';
import MobileApp from './MobileApp';
import WebApp from './WebApp';
import usePlatform from '../../hooks/usePlatform';
import SplashPage from '../../pages/SplashPage';
import ProfileCompletionPage from '../../pages/profileCompletion/ProfileCompletionPage';
import { User } from '../../types/userTypes';
import useCurrentUser from '../../hooks/useCurrentUser';
import {BreakpointProvider} from "../../context/breakpointContext";

const AppWrapper: React.FC = () => {
  const { isMobileApp } = usePlatform();
  const [isSplashVisible, setIsSplashVisible] = useState<boolean>(true);
  const [isProfileCompletionPageVisible, setIsProfileCompletionPageVisible] = useState<boolean>(false);

  const onUserSuccess = async (userData: User) => {
    const isUserAuthenticated = userData ? !!userData.email : false;
    const isProfileComplete = userData ? userData.isProfileComplete : false;

    if (isUserAuthenticated && !isProfileComplete) {
      setIsProfileCompletionPageVisible(true);
    } else {
      setIsProfileCompletionPageVisible(false);
    }
    setIsSplashVisible(false);
  };

  const { isUserFetching, isUserError, refetchUser } = useCurrentUser(onUserSuccess);

  const queries: {[key: string]: string} = {
    xs: '(min-width: 0px)',
    sm: '(min-width: 576px)',
    md: '(min-width: 768px)',
    lg: '(min-width: 992px)',
    xl: '(min-width: 1200px)',
  };

  return (
    isSplashVisible
      ? <SplashPage showRetryButton={!isUserFetching && isUserError} onRetryButtonClick={refetchUser} />
      : (
        <BreakpointProvider queries={queries}>
          {isProfileCompletionPageVisible
            ? <ProfileCompletionPage/>
            : (
              isMobileApp ? <MobileApp/> : <WebApp/>
            )
          }
        </BreakpointProvider>
      )
  );
};

export default AppWrapper;
