import React from 'react';
import { IonContent, IonGrid, IonPage, IonRow, IonCol, IonSpinner, IonButton } from '@ionic/react';
import ionicLogo from '../ionicLogo.svg';
import Toast from '../components/toast/Toast';
import { useTranslation } from 'react-i18next';
import './splashPage.css';

interface Props {
  showRetryButton: boolean;
  onRetryButtonClick: () => void;
}

const SplashPage: React.FC<Props> = ({ showRetryButton, onRetryButtonClick }) => {
  const { t } = useTranslation();

  return (
    <IonPage data-cy="SplashPage">
      <IonContent>
        <IonGrid className="page-full-height">
          <IonRow className="page-full-height ion-justify-content-center">
            <IonCol className="ion-align-self-center ion-text-center">
              <div className="ion-padding">
                <img className="splash-logo" alt="Tangoer" src={ionicLogo} data-cy="SplashPage-Img-Logo" />
              </div>
              {showRetryButton
                ? <IonButton onClick={onRetryButtonClick} data-cy="SplashPage-Button-Retry">{t('button.retry')}</IonButton>
                : (
                  <>
                    <div className="ion-padding" data-cy="SplashPage-LoadingIndicator">{t('splash_page.loading')}</div>
                    <IonSpinner className="ion-padding" name="dots" />
                  </>
                )
              }
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
      <Toast />
    </IonPage>
  );
};

export default React.memo(SplashPage);
