import React from 'react';
import MobileAppLayout from '../../components/layout/MobileAppLayout';
import { IonText } from '@ionic/react';
import { useTranslation } from 'react-i18next';

const MobileAppFriendsPage: React.FC = () => {
  const { t } = useTranslation();

  return (
    <MobileAppLayout title={t('friends_page.title')}>
      <IonText>{t('Friends Page')}</IonText>
    </MobileAppLayout>
  );
};

export default React.memo(MobileAppFriendsPage);
