import React, { SyntheticEvent } from 'react';
import { IonIcon } from '@ionic/react';
import { notificationsOutline } from 'ionicons/icons';
import { Link } from 'react-router-dom';

const NotificationsNavigation: React.FC = () => {

  const handleOnNotificationsLinkClick = (event: SyntheticEvent) => {
    event.preventDefault();
    // Todo: implement
    console.log('notifications');
  };

  return (
    <Link to="/" onClick={handleOnNotificationsLinkClick} className="ion-padding">
      <IonIcon icon={notificationsOutline} className="navigation-icon" />
    </Link>
  );
};

export default NotificationsNavigation;
