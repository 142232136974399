import React, { useState } from 'react';
import {
  IonButton,
  IonIcon,
  IonItem,
  IonList,
  IonText,
} from '@ionic/react';
import { useTranslation } from 'react-i18next';
import { warningOutline } from "ionicons/icons";
import './profileCompletionForm.css';
import ValidationError from '../../errors/ValidationError';
import { Violation } from '../../types/apiTypes';
import UnsupportedImageTypeError from '../../errors/UnsupportedImageTypeError';
import useTakeProfilePhoto from '../../hooks/useTakeProfilePhoto';
import ProfileCompletionPhoto from '../profileCompletion/ProfileCompletionPhoto';
import useCurrentUser from '../../hooks/useCurrentUser';
import { showToast } from '../../actions/toastActions';
import { useDispatch } from 'react-redux';
import ExceededFileSizeError from '../../errors/ExceededFileSizeError';
import CameraAccessDeniedError from '../../errors/CameraAccessDeniedError';

interface Props {
  isBackButtonVisible: boolean;
  onSuccess: () => void;
  onBackButtonClick: () => void;
  onSkipButtonClick: () => void;
}

const ProfileCompletionStep2Form: React.FC<Props> = ({ isBackButtonVisible, onSuccess, onBackButtonClick, onSkipButtonClick }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { userFullName, userProfilePhoto } = useCurrentUser();

  const [contextError, setContextError] = useState<string | null>(null);

  const handleTakeProfilePhotoSuccess = () => {
    setContextError(null);
    dispatch(showToast(t('profile_completion_step_2_photo_success')));
    // do nothing else, uploaded photo triggers change in user's data which will display uploaded photo
  };

  const handleTakeProfilePhotoError = (error: Error) => {
    if (error instanceof ValidationError) {
      const violationErrors: string[] = [];
      error.violations.forEach((violation: Violation) => {
        violationErrors.push(violation.message);
      });
      setContextError(violationErrors.join('<br>'));
    } else if (error instanceof UnsupportedImageTypeError) {
      setContextError(error.message);
    } else if (error instanceof ExceededFileSizeError) {
      setContextError(error.message);
    } else if (error instanceof CameraAccessDeniedError) {
      setContextError(error.message);
    } else {
      setContextError(t('error.something_went_wrong'));
    }
  };

  const { takeProfilePhoto } = useTakeProfilePhoto(handleTakeProfilePhotoSuccess, handleTakeProfilePhotoError);

  const handleChoosePhotoButtonClick = async (): Promise<void> => {
    setContextError(null);
    await takeProfilePhoto();
  };

  return (
    <>
      <IonList>
        {contextError && (
          <IonItem lines="none" className="ion-padding-bottom">
            <IonIcon icon={warningOutline} color="danger" slot="start" />
            <IonText color="danger"><span dangerouslySetInnerHTML={{ __html: contextError}} /></IonText>
          </IonItem>
        )}

        <IonItem lines="none" className="ion-padding-bottom">
          <ProfileCompletionPhoto
            src={userProfilePhoto ? `${userProfilePhoto}?profile=thumb_200_200` : undefined}
            alt={userFullName}
          />
        </IonItem>

        <IonItem lines="none">
          <IonButton
            size="default"
            color="secondary"
            className="button-upload"
            onClick={handleChoosePhotoButtonClick}
          >
            {userProfilePhoto ? t('button.change_photo') : t('button.upload_photo')}
          </IonButton>
        </IonItem>
      </IonList>

      <div className="form-footer">
        {isBackButtonVisible &&
          <div className="form-footer__buttons-left">
            <IonButton
                type="button"
                size="default"
                fill="clear"
                color="secondary"
                onClick={onBackButtonClick}
                data-cy="ProfileCompletionStep2Form-Button-Back"
            >
              {t('button.back')}
            </IonButton>
          </div>
        }
        <div className="form-footer__buttons-right">
          {!userProfilePhoto &&
            <IonButton
              type="button"
              size="default"
              fill="clear"
              color="secondary"
              onClick={onSkipButtonClick}
              data-cy="ProfileCompletionStep2Form-Button-Skip"
            >
              {t('button.skip')}
            </IonButton>
          }
          <IonButton
            type="button"
            size="default"
            color="primary"
            disabled={!userProfilePhoto}
            onClick={onSuccess}
            data-cy="ProfileCompletionStep2Form-Button-Continue"
          >
            {t('button.continue')}
          </IonButton>
        </div>
        <div className="form-footer__clear-both" />
      </div>
    </>
  );
};

export default ProfileCompletionStep2Form;
