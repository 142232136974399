import React, { useRef } from 'react';
import { CreateAnimation, IonAvatar, IonImg } from '@ionic/react';
import './profileCompletionPhoto.css';

interface Props {
  src?: string;
  alt?: string;
}

const ProfileCompletionPhoto: React.FC<Props> = ({ src, alt }) => {
  const imageAnimationRef = useRef<CreateAnimation>(null);

  return (
      <IonAvatar className="profile-completion-photo">
        {src &&
          <CreateAnimation
            ref={imageAnimationRef}
            duration={100}
            iterations={1}
            fromTo={[
              { property: 'opacity', fromValue: '0', toValue: '1' },
            ]}
          >
            <IonImg
              src={src}
              onIonImgDidLoad={() => {
                imageAnimationRef.current?.animation.play();
              }}
              className={`profile-completion-photo-img`}
            />
          </CreateAnimation>
        }
      </IonAvatar>
  );
};

export default ProfileCompletionPhoto;
