import { apiRequest } from '../api/apiClient';
import { SaveLocationPayload, SaveLocationResponse } from '../types/locationTypes';

const saveLocation = async (payload: SaveLocationPayload): Promise<SaveLocationResponse> => {
  return await apiRequest(
    '/api/locations',
    'POST',
    JSON.stringify(payload),
    {
      'Content-Type': 'application/json',
      'Accept': 'application/ld+json'
    }
  );
};

export default { saveLocation };
