import { apiRequest } from '../api/apiClient';
import {
  AssignMediaToUserPayload,
  AuthenticateFacebookUserPayload,
  AuthenticateUserPayload,
  ForgotPasswordPayload,
  UpdateUserPayload,
  RegisterUserPayload,
  ResetPasswordPayload,
  User,
  FlagProfileAsCompletePayload
} from '../types/userTypes';
import UnauthorizedError from '../errors/UnauthorizedError';

const getCurrentUser = async (): Promise<User | null> => {
  try {
    return await apiRequest('/api/me', 'GET', undefined, { 'Accept': 'application/ld+json' });
    // return await apiRequest('http://google.com/api/me');
  } catch (error) {
    if (error instanceof UnauthorizedError) {
      return null;
    }
    throw error;
  }
};

const updateUser = async (payload: UpdateUserPayload): Promise<void> => {
  await apiRequest(
    `/api/users/${payload.userId}`,
    'PUT',
    JSON.stringify(payload),
    {
      'Content-Type': 'application/json',
      'Accept': 'application/ld+json'
    }
  );
};

const flagProfileAsComplete = async (payload: FlagProfileAsCompletePayload): Promise<void> => {
  await apiRequest(
    `/api/users/${payload.userId}`,
    'PATCH',
    JSON.stringify({ isProfileComplete: true }),
    {
      'Content-Type': 'application/merge-patch+json',
      'Accept': 'application/ld+json'
    }
  );
};

const assignMediaToUser = async (payload: AssignMediaToUserPayload): Promise<void> => {
  await apiRequest(
    `/api/users/${payload.userId}`,
    'PATCH',
    JSON.stringify({ profilePhoto: payload.profilePhoto }),
    {
      'Content-Type': 'application/merge-patch+json',
      'Accept': 'application/ld+json'
    }
  );
};

const forgotPassword = async (payload: ForgotPasswordPayload): Promise<void> => {
  await apiRequest(
    '/api/forgot-password',
    'POST',
    JSON.stringify({ email: payload.email }),
    {
      'Content-Type': 'application/json',
      'Accept': 'application/ld+json'
    }
  );
};

const resetPassword = async (payload: ResetPasswordPayload): Promise<void> => {
  await apiRequest(
    '/api/reset-password',
    'POST',
    JSON.stringify({ token: payload.token, password: payload.password }),
    {
      'Content-Type': 'application/json',
      'Accept': 'application/ld+json'
    }
  );
};

const registerUser = async (payload: RegisterUserPayload): Promise<User> => {
  return await apiRequest(
    '/api/registration',
    'POST',
    JSON.stringify({ email: payload.email, password: payload.password }),
    {
      'Content-Type': 'application/json',
      'Accept': 'application/ld+json'
    }
  );
};

const authenticateUser = async (payload: AuthenticateUserPayload): Promise<User> => {
  return await apiRequest(
    '/api/login_check',
    'POST',
    JSON.stringify({ username: payload.email, password: payload.password }),
    {
      'Content-Type': 'application/json',
      'Accept': 'application/ld+json'
    }
  );
};

const authenticateFacebookUser = async (payload: AuthenticateFacebookUserPayload): Promise<User> => {
  return await apiRequest(
    '/api/facebook_check',
    'POST',
    JSON.stringify({ accessToken: payload.accessToken }),
    {
      'Content-Type': 'application/json',
      'Accept': 'application/ld+json'
    }
  );
};

const logoutUser = async (): Promise<User> => {
  return await apiRequest(
    '/api/logout',
    'GET',
    undefined,
    {
      'Accept': 'application/ld+json'
    }
  );
};

export default {
  getCurrentUser,
  updateUser,
  flagProfileAsComplete,
  assignMediaToUser,
  forgotPassword,
  resetPassword,
  registerUser,
  authenticateUser,
  authenticateFacebookUser,
  logoutUser,
};
