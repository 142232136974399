import React, { useState } from 'react';
import { IonButton, IonIcon, IonInput, IonItem, IonLabel, IonList, IonText } from '@ionic/react';
import { useDispatch } from 'react-redux';
import ValidationError from '../../errors/ValidationError';
import { Violation } from '../../types/apiTypes';
import useForgotPassword from '../../hooks/useForgotPassword';
import { hideLoader, showLoader } from '../../actions/loaderActions';
import { Controller, useForm } from 'react-hook-form';
import { warningOutline } from 'ionicons/icons';
import { ErrorMessage } from '@hookform/error-message';
import { useTranslation } from 'react-i18next';

interface Props {
  onSuccess: () => void;
}

interface FormData {
  email: string;
}

const ForgotPasswordForm: React.FC<Props> = ({ onSuccess }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { control, errors, handleSubmit, setError } = useForm<FormData>();
  const [contextError, setContextError] = useState<string | null>(null);

  const onForgotPasswordSuccess = () => {
    dispatch(hideLoader());
    onSuccess();
  };

  const onForgotPasswordError = (error: Error) => {
    if (error instanceof ValidationError) {
        error.violations.forEach((violation: Violation) => {
          // @ts-ignore TS2345 Argument of type 'string' is not assignable to parameter of type '"password"'.
          setError(violation.propertyPath, { type: 'manual', message: violation.message });
        });
    } else {
      setContextError(t('error.something_went_wrong'));
    }
  };

  const { forgotPassword } = useForgotPassword(onForgotPasswordSuccess, onForgotPasswordError);

  const handleFormSubmit = (data: FormData): void => {
    dispatch(showLoader());
    setContextError(null);
    forgotPassword.mutate({ email: data.email });
  };

  return (
    <form onSubmit={handleSubmit(handleFormSubmit)} noValidate={true}>
      <IonList>
        {contextError && (
          <IonItem lines="full">
            <IonIcon icon={warningOutline} color="danger" slot="start" />
            <IonText color="danger">{contextError}</IonText>
          </IonItem>
        )}
        <IonItem>
          <IonLabel position="floating" color="primary">{t('label.email')}</IonLabel>
          <Controller
            name="email"
            control={control}
            defaultValue={false}
            rules={{
              required: { value: true, message: t('validation.please_enter_your_email') },
              validate: (data: string) => {
                if(!/^[A-Z0-9._%+-]+@(?:[A-Z0-9-]+\.)+[A-Z]{2,}$/i.test(data)) {
                  return String(t('validation.invalid_email'));
                }
              }
            }}
            render={({ value, onChange, onBlur }) => {
              return (
                <IonInput
                  type="email"
                  inputmode="email"
                  name="email"
                  value={value}
                  required={true}
                  onIonChange={onChange}
                  onIonBlur={onBlur}
                  autofocus={true}
                  data-cy="ForgotPasswordForm-Input-Email"
                />
              );
            }}
          />
        </IonItem>
        <ErrorMessage name="email" errors={errors} render={ ({ message }) => (
          <IonText color="danger">
            <p className="ion-padding-start ion-text-start">{message}</p>
          </IonText>
        )}/>
      </IonList>
      <IonButton type="submit" expand="full" size="default" color="primary" data-cy="ForgotPasswordForm-Button-Submit">
        {t('password_recovery_page.button.send_me_recovery_link')}
      </IonButton>
    </form>
  );
};

export default ForgotPasswordForm;
