import { apiRequest } from '../api/photonApiClient';

const searchCitiesAndTowns = async (query: string) => {
  return await apiRequest(
    `/api/?q=${query}&osm_tag=place:city&osm_tag=place:town`,
    'GET',
    undefined,
    { 'Accept': 'application/json', 'accept-language': 'en-US' }
    );
};

export default { searchCitiesAndTowns };
