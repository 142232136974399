import React from 'react';
import { IonModal } from '@ionic/react';
import ModalContentRegister from './ModalContentRegister';
import ModalContentLogin from './ModalContentLogin';
import ModalContentRegisterClassic from './ModalContentRegisterClassic';
import ModalContentLoginClassic from './ModalContentLoginClassic';
import ModalContentForgotPassword from './ModalContentForgotPassword';
import { AuthModalAction } from '../../types/authModalTypes';
import './authModal.css';

export interface AuthModalProps {
  isOpen: boolean;
  action: AuthModalAction;
  onClose: () => void;
  onChangeAction: (actions: AuthModalAction) => void;
}

const AuthModal: React.FC<AuthModalProps> = ({ isOpen, action, onClose, onChangeAction }) => {
  const handleModalCloseButtonClick = (): void => {
    onClose();
  };

  const handleSetAuthModalAction = (action: AuthModalAction): void => {
    onChangeAction(action);
  };

  const handleModalRegisterBackButtonClick = (): void => {
    onChangeAction('register');
  };

  const handleModalLoginBackButtonClick = (): void => {
    onChangeAction('login');
  };

  const handleModalForgotPasswordBackButtonClick = (): void => {
    onChangeAction('login-classic');
  };

  const handleSuccess = (): void => {
    onClose();
  };

  const renderComponentByAction = (action: AuthModalAction) => {
    switch(action) {
      case 'register':
        return <ModalContentRegister onCloseButtonClick={handleModalCloseButtonClick} onSetAuthModalAction={handleSetAuthModalAction} onSuccess={handleSuccess} />;
      case 'register-classic':
        return <ModalContentRegisterClassic onBackButtonClick={handleModalRegisterBackButtonClick} onCloseButtonClick={handleModalCloseButtonClick} onSuccess={handleSuccess} />;
      case 'login':
        return <ModalContentLogin onCloseButtonClick={handleModalCloseButtonClick} onSetAuthModalAction={handleSetAuthModalAction} onSuccess={handleSuccess} />;
      case 'login-classic':
        return <ModalContentLoginClassic onBackButtonClick={handleModalLoginBackButtonClick} onSetAuthModalAction={handleSetAuthModalAction} onCloseButtonClick={handleModalCloseButtonClick} onSuccess={handleSuccess} />;
      case 'forgot-password':
        return <ModalContentForgotPassword onBackButtonClick={handleModalForgotPasswordBackButtonClick} onCloseButtonClick={handleModalCloseButtonClick} />
    }
  };

  return (
    <IonModal
      isOpen={isOpen}
      onDidDismiss={isOpen ? onClose : () => {} }
      swipeToClose={true}
    >
      {renderComponentByAction(action)}
    </IonModal>
  );
};

export default AuthModal;
