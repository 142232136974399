import React, { useRef, useState } from 'react';
import {
  IonButton,
  IonContent,
  IonHeader,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonModal,
  IonPage,
  IonSpinner,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import { searchOutline } from 'ionicons/icons';
import { useTranslation } from 'react-i18next';
import './selectAutocompleteWidget.css';

export interface SearchResultItem {
  value: any;
  label: string;
}

interface Props {
  name: string;
  value: string;
  displayValue: string;
  onSearchValueIsEntered: (query: string) => void;
  onItemIsChosen: (searchResultItem: SearchResultItem) => void;
  isSearching: boolean;
  searchResultItems: SearchResultItem[];
}

const SelectAutocompleteWidget: React.FC<Props> = ({ name, value, displayValue, onItemIsChosen, onSearchValueIsEntered, isSearching, searchResultItems}) => {
  const { t } = useTranslation();
  const inputRef = useRef<HTMLIonInputElement>(null);
  const searchInputRef = useRef<HTMLIonInputElement>(null);

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const closeModal = async () => {
    if (isModalOpen) {
      onSearchValueIsEntered('');
      await setIsModalOpen(false);
    }
  };

  const handleInputElementClick = async () => {
    await inputRef.current!.setBlur();
    await setIsModalOpen(true);
  };

  const handleModalDidPresent = async () => {
    await searchInputRef.current!.setFocus();
  };

  const handleSearchResultItemClick = async (searchResultItem: SearchResultItem) => {
    await setIsModalOpen(false);
    onItemIsChosen(searchResultItem);
  };

  const renderSearchResultItems = (searchResultItems: SearchResultItem[]) => {
    return searchResultItems
      .map((searchResultItem: SearchResultItem, index) => {
        return (
          <IonItem key={index} button onClick={() => { handleSearchResultItemClick(searchResultItem) }}>
            {/* Fix for Safari: https://github.com/ionic-team/ionic-framework/issues/21939#issuecomment-679936379 */}
            <div tabIndex={0}>
              <IonLabel>{searchResultItem.label}</IonLabel>
            </div>
          </IonItem>
        );
      });
  };

  return (
    <>
      {/* Readonly input field that holds display value and is clickable */}
      <div className="widget-wrapper">
        <IonInput
          ref={inputRef}
          type="text"
          inputmode="text"
          name=""
          value={displayValue}
          readonly={true}
          required={false}
          onIonFocus={handleInputElementClick}
          data-cy=""
        />
      </div>
      {/* Hidden input field that holds submittable location value */}
      <input
        type="hidden"
        name={name}
        value={value}
        required={false}
      />
      <IonModal
        isOpen={isModalOpen}
        onDidPresent={handleModalDidPresent}
        onDidDismiss={closeModal}
        swipeToClose={true}
      >
        <IonPage>
          <IonHeader>
            <IonToolbar>
              <IonTitle>{t('Current city')}</IonTitle>
            </IonToolbar>
            <IonToolbar>
              {/* Searchbar element */}
              <div className="searchbar-wrapper">
                <IonIcon icon={searchOutline} className="searchbar-icon-search" />
                <IonInput
                  ref={searchInputRef}
                  clearInput={true}
                  type="text"
                  inputmode="text"
                  name="citySearchBar"
                  autofocus={true}
                  debounce={100}
                  onIonChange={e => { onSearchValueIsEntered(e.detail.value!); }}
                  placeholder={t('Search')}
                />
              </div>
              <IonButton slot="end" onClick={closeModal} fill="clear">{t('Cancel')}</IonButton>
            </IonToolbar>
          </IonHeader>
          <IonContent fullscreen>
            <IonList>
              {isSearching &&
                <IonItem><div tabIndex={0}>
                  <IonSpinner className="ion-padding" name="dots"/>
                </div></IonItem>
              }
              {renderSearchResultItems(searchResultItems)}
            </IonList>
          </IonContent>
        </IonPage>
      </IonModal>
    </>
  );
};

export default SelectAutocompleteWidget;
