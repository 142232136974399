import locationModel from '../model/location';
import { useDispatch } from 'react-redux';
import NetworkError from '../errors/NetworkError';
import { showToast } from '../actions/toastActions';
import { SaveLocationResponse } from '../types/locationTypes';

const useSaveLocation = (onSuccess: (response: SaveLocationResponse) => void, onError: (error: Error) => void) => {
  const dispatch = useDispatch();

  const handleSaveLocationError = async (error: Error) => {
    if (error instanceof NetworkError) {
      dispatch(showToast('Looks like you don\'t have a connection to the internet.', true));
    }

    await onError(error);
  };

  const saveLocation = async (osmShortTypeAndId: string) => {
    try {
      const saveLocationResponse = await locationModel.saveLocation({osmShortTypeAndId: osmShortTypeAndId});
      await onSuccess(saveLocationResponse);
    }
    catch (error) {
      console.error(error);
      await handleSaveLocationError(error);
    }
  };

  return { saveLocation };
};

export default useSaveLocation;
