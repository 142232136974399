import mediaObject from '../model/mediaObject';
import user from '../model/user';
import NetworkError from '../errors/NetworkError';
import { showToast } from '../actions/toastActions';
import { useDispatch } from 'react-redux';
import { useQueryClient } from 'react-query';
import useCurrentUser from './useCurrentUser';

const useUploadProfilePhoto = (onSuccess: () => void, onError: (error: Error) => void) => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const { userData } = useCurrentUser();

  const handleSuccess = async () => {
    await queryClient.invalidateQueries('user');
    onSuccess();
  };

  const handleError = (error: Error) => {
    if (error instanceof NetworkError) {
      dispatch(showToast('Looks like you don\'t have a connection to the internet.', true));
    }
    onError(error);
  };

  const uploadProfilePhoto = async (imageBlob: Blob) => {
    const data = new FormData();
    data.append('file', imageBlob);

    try {
      // Upload photo to API
      const uploadMediaResult = await mediaObject.uploadMedia(data);

      // Link uploaded photo to logged in user
      await user.assignMediaToUser({ userId: userData!.id, profilePhoto: uploadMediaResult['@id'] });

      await handleSuccess();
    } catch (error) {
      console.error(error);
      handleError(error);
    }
  };

  return { uploadProfilePhoto };

};

export default useUploadProfilePhoto;
