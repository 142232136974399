import React, { useState } from 'react';
import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonCol,
  IonGrid,
  IonIcon,
  IonItem,
  IonRow,
  IonText
} from '@ionic/react';
import { useTranslation } from 'react-i18next';
import useFlagProfileAsComplete from '../../hooks/useFlagProfileAsComplete';
import useCurrentUser from '../../hooks/useCurrentUser';
import ValidationError from "../../errors/ValidationError";
import { warningOutline } from "ionicons/icons";

interface Props {
  isBackButtonVisible: boolean;
  onBackButtonClick: () => void;
}

const ProfileCompletionStep3: React.FC<Props> = ({ isBackButtonVisible, onBackButtonClick }) => {
  const { t } = useTranslation();
  const { userData } = useCurrentUser();
  const [contextError, setContextError] = useState<string | null>(null);

  const handleFlagProfileAsCompleteError = (error: Error) => {
    if (error instanceof ValidationError) {
      setContextError(t('error.unable_to_complete_your_profile'));
    } else {
      setContextError(t('error.something_went_wrong'));
    }
  };

  const { flagProfileAsComplete } = useFlagProfileAsComplete(handleFlagProfileAsCompleteError);

  const handleFinishButtonClick = () => {
    flagProfileAsComplete.mutate({ userId: userData!.id });
  };

  return (
    <IonGrid>
      <IonRow className="ion-justify-content-center">
        <IonCol sizeSm="8" sizeMd="7" sizeLg="6" sizeXl="5">
          <IonCard>
            <IonCardHeader>
              <IonCardTitle>{t('profile_completion_step_3_title')}</IonCardTitle>
            </IonCardHeader>
            <IonCardContent>
              {contextError && (
                <IonItem lines="none">
                  <IonIcon icon={warningOutline} color="danger" slot="start" />
                  <IonText color="danger">{contextError}</IonText>
                </IonItem>
              )}

              <IonItem lines="none">
                {t('profile_completion_step_3_content')}
              </IonItem>

              <div className="form-footer">
                {isBackButtonVisible &&
                  <div className="form-footer__buttons-left">
                    <IonButton
                        type="button"
                        size="default"
                        fill="clear"
                        color="secondary"
                        onClick={onBackButtonClick}
                        data-cy="ProfileCompletionStep2Form-Button-Back"
                    >
                      {t('button.back')}
                    </IonButton>
                  </div>
                }
                <div className="form-footer__buttons-right">
                  <IonButton
                    type="button"
                    size="default"
                    color="primary"
                    onClick={handleFinishButtonClick}
                    data-cy="ProfileCompletionStepOneForm-Button-Finish"
                  >
                    {t('button.finish')}
                  </IonButton>
                </div>
                <div className="form-footer__clear-both" />
              </div>
            </IonCardContent>
          </IonCard>
        </IonCol>
      </IonRow>
    </IonGrid>
  );
};

export default ProfileCompletionStep3;
