import React, { useState } from 'react';
import { IonButton, IonIcon, IonInput, IonItem, IonLabel, IonList, IonText } from '@ionic/react';
import { Controller, useForm } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { useDispatch } from 'react-redux';
import { hideLoader, showLoader } from '../../actions/loaderActions';
import { warningOutline } from 'ionicons/icons';
import UnauthorizedError from '../../errors/UnauthorizedError';
import useAuthentication from '../../hooks/useAuthentication';
import { useTranslation } from 'react-i18next';

interface Props {
  onSuccess: () => void;
}

interface FormData {
  email: string;
  password: string;
}

const LoginForm: React.FC<Props> = ({ onSuccess }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { control, errors, handleSubmit } = useForm<FormData>();
  const [contextError, setContextError] = useState<string | null>(null);

  const onAuthenticateSuccess = () => {
    dispatch(hideLoader());
    onSuccess();
  };

  const onAuthenticateError = (error: Error) => {
    if (error instanceof UnauthorizedError) {
      setContextError(t('error.invalid_credentials'));
    } else {
      setContextError(t('error.something_went_wrong'));
    }
    dispatch(hideLoader());
  };

  const { authenticateUser } = useAuthentication(onAuthenticateSuccess, onAuthenticateError);

  const handleFormSubmit = (data: FormData): void => {
    dispatch(showLoader());
    setContextError(null);
    authenticateUser.mutate({ email: data.email, password: data.password });
  };

  return (
    <form onSubmit={handleSubmit(handleFormSubmit)} noValidate={true}>
      <IonList>
        {contextError && (
          <IonItem lines="full">
            <IonIcon icon={warningOutline} color="danger" slot="start" />
            <IonText color="danger" data-cy="LoginForm-ContextErrorMessage">{contextError}</IonText>
          </IonItem>
        )}
        <IonItem>
          <IonLabel position="floating" color="primary">{t('label.email')}</IonLabel>
          <Controller
            name="email"
            control={control}
            defaultValue={false}
            rules={{
              required: { value: true, message: t('validation.please_enter_your_email') },
              validate: (data: string) => {
                if(!/^[A-Z0-9._%+-]+@(?:[A-Z0-9-]+\.)+[A-Z]{2,}$/i.test(data)) {
                  return String(t('validation.invalid_email'));
                }
              }
            }}
            render={({ value, onChange, onBlur }) => {
              return (
                <IonInput
                  type="email"
                  inputmode="email"
                  name="email"
                  value={value}
                  required={true}
                  onIonChange={onChange}
                  onIonBlur={onBlur}
                  autofocus={true}
                  data-cy="LoginForm-Input-Email"
                />
              );
            }}
          />
        </IonItem>
        <ErrorMessage name="email" errors={errors} render={ ({ message }) => (
          <IonText color="danger" data-cy="LoginForm-ValidationError-Email">
            <p className="ion-padding-start ion-text-start">{message}</p>
          </IonText>
        )}/>

        <IonItem>
          <IonLabel position="floating" color="primary">{t('label.password')}</IonLabel>
          <Controller
            name="password"
            control={control}
            defaultValue={false}
            rules={{
              required: { value: true, message: t('validation.please_enter_your_password') },
            }}
            render={({ value, onChange, onBlur }) => {
              return (
                <IonInput
                  type="password"
                  name="password"
                  clearOnEdit={true}
                  value={value}
                  required={true}
                  onIonChange={onChange}
                  onIonBlur={onBlur}
                  data-cy="LoginForm-Input-Password"
                />
              );
            }}
          />
        </IonItem>
        <ErrorMessage name="password" errors={errors} render={({ message }) => (
          <IonText color="danger">
            <p className="ion-padding-start ion-text-start">{message}</p>
          </IonText>
        )}/>

      </IonList>
      <IonButton type="submit" expand="full" size="default" color="primary" data-cy="LoginForm-Button-Submit">{t('button.log_in')}</IonButton>
    </form>
  );
};

export default LoginForm;
