import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IonButton } from '@ionic/react';
import { Plugins, registerWebPlugin } from '@capacitor/core';
import { FacebookLogin } from '@capacitor-community/facebook-login';
import { hideLoader, showLoader } from '../actions/loaderActions';
import { useDispatch } from 'react-redux';
import useFacebookAuthentication from '../hooks/useFacebookAuthentication';
import MissingFacebookScopeError from '../errors/MissingFacebookScopeError';
import config from '../config/config';
import { showToast } from '../actions/toastActions';
import useIsMounted from 'ismounted';

interface Props {
  onSuccess: () => void;
  onError: (error: Error, missingScope?: string) => void;
}

const LoginWithFacebookButton: React.FC<Props> = (props) => {
  const { t } = useTranslation();

  return (
    <FacebookLoginButton label={t('fb_button.log_in_with_facebook')} {...props} />
  );
};

const ConnectWithFacebookButton: React.FC<Props> = (props) => {
  const { t } = useTranslation();

  return (
    <FacebookLoginButton label={t('fb_button.connect_with_facebook')} {...props} />
  );
};

const ContinueWithFacebookButton: React.FC<Props> = (props) => {
  const { t } = useTranslation();

  return (
    <FacebookLoginButton label={t('fb_button.continue_with_facebook')} {...props} />
  );
};

interface FacebookLoginButtonProps {
  label: string;
  onSuccess: () => void;
  onError: (error: Error, missingScope?: string) => void;
}

const FacebookLoginButton: React.FC<FacebookLoginButtonProps> = ({ label, onSuccess, onError }) => {
  const isMounted = useIsMounted();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [isDisabled, setIsDisabled] = useState<boolean>(false);

  const handleAuthenticateFacebookUserSuccess = () => {
    dispatch(hideLoader());
    onSuccess();
  };

  const handleAuthenticateFacebookUserError = (error: Error) => {
    dispatch(hideLoader());
    if (error instanceof MissingFacebookScopeError) {
      onError(new Error(t('error.missing_email_permission')));

      return;
    } else {
      onError(new Error(t('error.unable_to_authenticate_with_facebook')));

      return;
    }
  };

  const { authenticateFacebookUser } = useFacebookAuthentication(handleAuthenticateFacebookUserSuccess, handleAuthenticateFacebookUserError);

  const handleLoginButtonClick = async () => {
    setIsDisabled(true);

    let result;

    // Todo: show loader with title "Waiting for Facebook authentication..."

    try {
      result = await Plugins.FacebookLogin.login({permissions: config.facebookPermissions, authType: 'rerequest'});
      if (isMounted.current) {
        setIsDisabled(false);
      }

    } catch (error) {
      console.error(error);
      // If user cancels Facebook authentication by closing FB login window error is thrown with accessToken as null
      if (error.accessToken?.token === null) {
        // do nothing
        console.log('do nothing');
      } else {
        dispatch(showToast(t('error.unable_to_authenticate_with_facebook'), true));
      }
      if (isMounted.current) {
        setIsDisabled(false);
      }

      return;
    }

    if (result && result.accessToken) {
      dispatch(showLoader());
       authenticateFacebookUser.mutate({ accessToken: result.accessToken.token } );
    }
  };

  useEffect(() => {
    registerWebPlugin(FacebookLogin);
  }, []);

  return (
    <IonButton disabled={isDisabled} onClick={handleLoginButtonClick}>{t('fb_button.log_in_with_facebook')}</IonButton>
  );
};

export { LoginWithFacebookButton, ConnectWithFacebookButton, ContinueWithFacebookButton };
