import React from 'react';
import { IonContent, IonHeader, IonPage } from '@ionic/react';
import Loader from '../Loader';
import Toast from '../toast/Toast';

interface LayoutProps {
  toolbar: any;
};

const Layout: React.FC<LayoutProps> = ({ toolbar, children }) => {
  return (
    <IonPage>
      <IonHeader>
        {toolbar}
      </IonHeader>
      <IonContent>
        {children}
      </IonContent>
      <Loader />
      <Toast />
    </IonPage>
  );
};

export default Layout;
