export const SHOW_TOAST = 'SHOW_TOAST';
export const HIDE_TOAST = 'HIDE_TOAST';

export const showToast = (message: string, isDismissible?: boolean) => ({
  type: SHOW_TOAST,
  message: message,
  isDismissible: isDismissible ?? false
});

export const hideToast = () => ({
  type: HIDE_TOAST,
});
