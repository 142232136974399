import Compressor from "compressorjs";

const compressImage = async (imageBlob: Blob, options: Compressor.Options): Promise<Blob> => {
  return new Promise((resolve, reject) => {
        new Compressor(imageBlob, {
          ...options,
          success(result: Blob) {
            resolve(result);
          },
          error(err: Error) {
            reject(err);
          },
        });
      });
};

export default { compressImage };
