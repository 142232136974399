import { useMutation, useQueryClient } from 'react-query';
import userModel from '../model/user';
import { useDispatch } from 'react-redux';
import { showToast } from '../actions/toastActions';
import NetworkError from '../errors/NetworkError';
import { useTranslation } from 'react-i18next';

const useAuthentication = (onAuthenticateSuccess: any, onAuthenticateError: any) => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  const onSuccess = async () => {
    await queryClient.invalidateQueries('user');
    await onAuthenticateSuccess();
  };

  const onError = async (error: Error) => {
    if (error instanceof NetworkError) {
      dispatch(showToast(t('error.no_internet_connection'), true));
    }

    await onAuthenticateError(error);
  };

  const authenticateUser = useMutation(
    userModel.authenticateUser, {
      onSuccess: onSuccess,
      onError: onError,
    }
  );

  return { authenticateUser };
};

export default useAuthentication;
