import React from 'react';
import { IonButtons, IonTitle, IonToolbar } from '@ionic/react';
import SearchNavigation from '../navigation/SearchNavigation';
import useCurrentUser from '../../hooks/useCurrentUser';
import NotificationsNavigation from '../navigation/NotificationsNavigation';

interface MobileAppToolbarProps {
  title: string;
};

const MobileAppToolbar: React.FC<MobileAppToolbarProps> = ({ title }) => {
  const { isUserAuthenticated } = useCurrentUser();

  return (
    <IonToolbar>
      <IonTitle>{title}</IonTitle>
      <IonButtons slot="end">
        <SearchNavigation />
        {isUserAuthenticated && <NotificationsNavigation />}
      </IonButtons>
    </IonToolbar>
  );
};

export default MobileAppToolbar;
