import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { IonRouterOutlet, IonSplitPane } from '@ionic/react';
import Menu from '../Menu';
import WebAppProfilePage from '../../pages/profile/WebAppProfilePage';
import WebAppDiscoverPage from '../../pages/discover/WebAppDiscoverPage';
import WebAppEventsPage from '../../pages/events/WebAppEventsPage';
import WebAppMaestrosPage from '../../pages/maestros/WebAppMaestrosPage';
import WebAppFriendsPage from '../../pages/friends/WebAppFriendsPage';
import WebAppNotificationsPage from '../../pages/notifications/WebAppNotificationsPage';
import WebAppResetPasswordPage from '../../pages/resetPassword/WebAppResetPasswordPage';
import WebAppResetPasswordSuccessPage from '../../pages/resetPasswordSuccess/WebAppResetPasswordSuccessPage';

const WebApp: React.FC = () => {
  return (
    <IonSplitPane contentId="main">
      <Menu />
      <IonRouterOutlet id="main" animated={false}>
        <Route path="/reset-password/:token" render={() => <WebAppResetPasswordPage />} exact={true} />
        <Route path="/reset-password-success" render={() => <WebAppResetPasswordSuccessPage />} exact={true} />
        <Route path="/profile" render={() => <WebAppProfilePage />} exact={true} />
        <Route path="/notifications" render={() => <WebAppNotificationsPage />} exact={true} />
        <Route path="/discover" render={() => <WebAppDiscoverPage />} exact={true} />
        <Route path="/events" render={() => <WebAppEventsPage />} exact={true} />
        <Route path="/maestros" render={() => <WebAppMaestrosPage />} exact={true} />
        <Route path="/friends" render={() => <WebAppFriendsPage />} exact={true} />
        <Route path="/" render={() => <Redirect to="/discover" />} exact={true} />
      </IonRouterOutlet>
    </IonSplitPane>
  );
};

export default WebApp;
