import React, { useState } from 'react';
import {
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader, IonIcon, IonItem,
  IonRow, IonText,
  IonToolbar,
} from '@ionic/react';
import { Link } from 'react-router-dom';
import { warningOutline } from 'ionicons/icons';
import { ConnectWithFacebookButton } from '../FacebookLoginButton';
import { useTranslation, Trans } from 'react-i18next';
import { AuthModalAction } from '../../types/authModalTypes';

interface Props {
  onCloseButtonClick: () => void;
  onSetAuthModalAction: (action: AuthModalAction) => void;
  onSuccess: () => void;
}

const ModalContentRegister: React.FC<Props> = ({ onCloseButtonClick, onSetAuthModalAction, onSuccess }) => {
  const { t } = useTranslation();

  const [fbAuthError, setFbAuthError] = useState<string | null>(null);

  const handleCloseButtonClick = () => {
    onCloseButtonClick();
  };

  const handleRegisterClassicLinkClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    onSetAuthModalAction('register-classic');
  };

  const handleLoginLinkClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    onSetAuthModalAction('login');
  };

  const handleFacebookButtonSuccess = () => {
    setFbAuthError(null);
    onSuccess();
  };

  const handleFacebookButtonError = (error: Error) => {
    setFbAuthError(error.message);
  };

  return (
    <>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="end">
            <IonButton onClick={handleCloseButtonClick}>{t('modal.close')}</IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonGrid>
          <IonRow className="ion-justify-content-center">
            <IonCol sizeSm="10">
              <IonCard>
                <IonCardHeader>
                  <IonCardTitle className="ion-text-center"><h1>{t('registration_page.title')}</h1></IonCardTitle>
                </IonCardHeader>
                <IonCardContent className="ion-text-center">
                  {fbAuthError && (
                    <IonItem lines="none" className="ion-padding-bottom">
                      <IonText color="danger">
                        <p>{fbAuthError}</p>
                      </IonText>
                      <IonIcon icon={warningOutline} color="danger" slot="start" />
                    </IonItem>
                  )}
                  <div><ConnectWithFacebookButton onSuccess={handleFacebookButtonSuccess} onError={handleFacebookButtonError} /></div>
                  <div className="ion-padding-top">
                    {t('registration_page.easy_fast_and_safe')}
                  </div>
                  <div className="ion-padding-top ion-text-uppercase">
                    - {t('_or')} -
                  </div>
                  <div className="ion-padding-top">
                    <Trans
                      i18nKey="registration_page.create_a_profile_old_fashioned_way"
                      components={{ LINK: <Link to="/" onClick={handleRegisterClassicLinkClick} data-cy="ModalContentRegister-Link-RegisterWithEmail" /> }}
                    />
                  </div>
                  <div className="ion-padding-top ion-text-uppercase">
                    - {t('_or')} -
                  </div>
                  <div className="ion-padding-top">
                    <div>{t('registration_page.you_already_have_a_profile')}</div>
                    <Trans
                      i18nKey="registration_page.log_in_instead"
                      components={{ LINK: <Link to="/" onClick={handleLoginLinkClick} /> }}
                    />
                  </div>
                </IonCardContent>
              </IonCard>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </>
  );
};

export default ModalContentRegister;
