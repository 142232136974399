import React, { SyntheticEvent } from 'react';
import {
  IonAvatar,
  IonContent,
  IonHeader,
  IonIcon,
  IonImg,
  IonItem,
  IonLabel,
  IonList,
  IonMenu,
  IonMenuToggle
} from '@ionic/react';
import {
  calendarOutline,
  homeOutline,
  logInOutline,
  notificationsOutline,
  peopleCircleOutline,
  peopleOutline,
  personAddOutline
} from 'ionicons/icons';
import { useLocation } from 'react-router';
import { useBreakpoint } from '../context/breakpointContext';
import AuthModal from './authModal/AuthModal';
import useCurrentUser from '../hooks/useCurrentUser';
import { useTranslation } from 'react-i18next';
import useAuthModal from '../hooks/useAuthModal';
import ionicLogo from '../ionicLogo.svg';
import avatar from '../avatar.svg';
import './menu.css';

interface MenuItem {
  path: string;
  label?: string;
  icon?: string;
  children?: any
  onClick?: (event: SyntheticEvent) => void;
  dataCy?: string;
}

const Menu: React.FC = () => {
  const breakpoints = useBreakpoint();
  const location = useLocation();
  const isWidthLg = breakpoints['lg'];
  const { isUserAuthenticated, userFullName, userProfilePhoto } = useCurrentUser();
  const { authModalProps, openLoginModal, openRegistrationModal } = useAuthModal();
  const { t } = useTranslation();

  const handleRegisterLinkClick = (event: SyntheticEvent): void => {
    event.preventDefault();
    openRegistrationModal();
  };

  const handleLoginLinkClick = (event: SyntheticEvent): void => {
    event.preventDefault();
    openLoginModal();
  };

  const mainMenuItems: MenuItem[] = [
    { label: t('navigation.discover'), path: '/discover', icon: homeOutline },
    { label: t('navigation.events'), path: '/events', icon: calendarOutline },
    { label: t('navigation.maestros'), path: '/maestros', icon: peopleOutline },
    { label: t('navigation.friends'), path: '/friends', icon: peopleCircleOutline }
  ];

  const authenticationMenuItems: MenuItem[] = [
    { label: t('navigation.register'), path: '/#register', icon: personAddOutline, onClick: handleRegisterLinkClick },
    { label: t('navigation.log_in'), path: '/#log_in', icon: logInOutline, onClick: handleLoginLinkClick },
  ];

  const profileMenuItems: MenuItem[] = [
    {
      path: '/notifications',
      label: t('navigation.notifications'),
      icon: notificationsOutline,
      dataCy: 'SideMenu-Link-Notifications'
    },
    {
      path: '/profile',
      label: userFullName,
      children:
        <IonAvatar slot="start" className="menu-profile-avatar">
          <IonImg alt={userFullName} src={userProfilePhoto ? `${userProfilePhoto}?profile=thumb_28_28` : avatar} />
        </IonAvatar>,
      dataCy: 'SideMenu-Link-Profile'
    }
  ];

  const renderMenuItems = (menuItems: MenuItem[]) => {
    return menuItems
      .map(menuItem => {
        return (
          <IonMenuToggle key={menuItem.label} auto-hide="false">
            <IonItem
              className={location.pathname.startsWith(menuItem.path) ? 'selected' : undefined}
              detail={false}
              routerLink={menuItem.path}
              routerDirection="none"
              onClick={menuItem.onClick}
              data-cy={menuItem.dataCy ?? null}
            >
              {menuItem.icon && <IonIcon slot="start" icon={menuItem.icon}/>}
              {menuItem.children && menuItem.children}
              {menuItem.label && <IonLabel>{menuItem.label}</IonLabel>}
            </IonItem>
          </IonMenuToggle>
        );
      });
  };

  return (
    isWidthLg
      ? null
      : (
        <>
          <IonMenu side="start" type="overlay" contentId="main" data-cy="SideMenu">
            <IonHeader>
              <IonImg src={ionicLogo} className="ion-padding menu-logo" />
            </IonHeader>
            <IonContent forceOverscroll={false}>
              <IonList lines="none">
                {renderMenuItems(mainMenuItems)}
              </IonList>
              <IonList lines="none">
                {renderMenuItems(isUserAuthenticated ? profileMenuItems : authenticationMenuItems)}
              </IonList>
            </IonContent>
          </IonMenu>

          <AuthModal {...authModalProps} />
        </>
      )
  );
};

export default Menu;
