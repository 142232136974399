import React from 'react';
import {
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonRow,
  IonToolbar,
} from '@ionic/react';
import { chevronBackCircleOutline } from 'ionicons/icons';
import LoginForm from '../forms/LoginForm';
import { Link } from 'react-router-dom';
import { useTranslation, Trans } from 'react-i18next';
import { AuthModalAction } from '../../types/authModalTypes';

interface Props {
  onBackButtonClick: () => void;
  onCloseButtonClick: () => void;
  onSetAuthModalAction: (action: AuthModalAction) => void;
  onSuccess: () => void;
}

const ModalContentLoginClassic: React.FC<Props> = ({ onBackButtonClick, onCloseButtonClick, onSetAuthModalAction, onSuccess }) => {
  const { t } = useTranslation();

  const handleBackButtonClick = () => {
    onBackButtonClick();
  };

  const handleCloseButtonClick = () => {
    onCloseButtonClick();
  };

  const handleForgotPasswordLinkClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    onSetAuthModalAction('forgot-password');
  };

  const handleFormSuccess = () => {
    onSuccess();
  };

  return (
    <>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonButton onClick={handleBackButtonClick}>
              <IonIcon size="large" icon={chevronBackCircleOutline} />
            </IonButton>
          </IonButtons>
          <IonButtons slot="end">
            <IonButton onClick={() => handleCloseButtonClick()}>{t('modal.close')}</IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonGrid>
          <IonRow className="ion-justify-content-center">
            <IonCol sizeSm="10">
              <IonCard>
                <IonCardHeader>
                  <IonCardTitle className="ion-text-center"><h1>{t('classic_login_page.title')}</h1></IonCardTitle>
                  <IonCardSubtitle className="ion-text-center">{t('_with_email')}</IonCardSubtitle>
                </IonCardHeader>
                <IonCardContent className="ion-text-center">
                  <LoginForm onSuccess={handleFormSuccess}/>
                  <div className="ion-margin-top ion-text-left">
                    <div className="ion-padding-top">
                      {t('classic_login_page.cant_remember_your_password')}
                    </div>
                    <div className="ion-padding-top">
                      <Trans
                        i18nKey="classic_login_page.recover_password_and_set_a_new_one"
                        components={{ LINK: <Link to="/#" onClick={handleForgotPasswordLinkClick} data-cy="ModalContentLoginClassic-Link-RecoverPassword" /> }}
                      />
                    </div>
                  </div>
                </IonCardContent>
              </IonCard>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </>
  );
};

export default ModalContentLoginClassic;
