import React from 'react';
import WebAppLayout from '../../components/layout/WebAppLayout';
import ResetPasswordContent from './ResetPasswordContent';

const WebAppResetPasswordPage: React.FC = () => {
  return (
    <WebAppLayout>
      <ResetPasswordContent />
    </WebAppLayout>
  );
};

export default React.memo(WebAppResetPasswordPage);
