import { useMutation, useQueryClient } from 'react-query';
import userModel from '../model/user';
import { useDispatch } from 'react-redux';
import NetworkError from '../errors/NetworkError';
import { showToast } from '../actions/toastActions';

const useUpdateUser = (onSuccess: () => void, onError: (error: Error) => void) => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const onUpdateUserSuccess = async () => {
    await queryClient.invalidateQueries('user');
    onSuccess();
  };

  const onUpdateUserError = (error: Error) => {
    if (error instanceof NetworkError) {
      dispatch(showToast('Looks like you don\'t have a connection to the internet.', true));
    }

    onError(error);
  };

  const updateUser = useMutation(
    userModel.updateUser, {
      onSuccess: onUpdateUserSuccess,
      onError: onUpdateUserError,
    }
  );

  return { updateUser };
};

export default useUpdateUser;
