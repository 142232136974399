import { isPlatform } from '@ionic/react';

const usePlatform = () => {
  const isMobileApp = isPlatform('hybrid');
  const isIosApp = isPlatform('hybrid') && isPlatform('ios');

  return { isMobileApp, isIosApp };
};

export default usePlatform;
