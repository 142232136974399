import React from 'react';
import { IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonCol, IonGrid, IonRow } from '@ionic/react';
import ResetPasswordForm from '../../components/forms/ResetPasswordForm';
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';

const ResetPasswordContent: React.FC = () => {
  const history = useHistory();
  let { token } = useParams();
  const { t } = useTranslation();

  const handleResetPasswordFormSuccess = () => {
    history.replace('/reset-password-success');
  };

  return (
    <IonGrid className="layout-full-height">
      <IonRow className="layout-full-height ion-justify-content-center">
        <IonCol size-lg="4" size-md="6" size-sm="8" size-xs="12" className="ion-align-self-center">
          <IonCard>
            <IonCardHeader>
              <IonCardTitle>{t('reset_password_page.title')}</IonCardTitle>
            </IonCardHeader>
            <IonCardContent>
              <ResetPasswordForm token={token} onSuccess={handleResetPasswordFormSuccess} />
            </IonCardContent>
          </IonCard>
        </IonCol>
      </IonRow>
    </IonGrid>
  );
};

export default ResetPasswordContent;
