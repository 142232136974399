import { apiRequest } from '../api/apiClient';
import { UploadMediaResponse } from '../types/mediaObjectTypes';

const uploadMedia = async (payload: any): Promise<UploadMediaResponse> => {
  return await apiRequest(
    '/api/media_objects',
    'POST',
    payload,
    {}  // don't set Content-Type header: https://muffinman.io/uploading-files-using-fetch-multipart-form-data/
  );
};

export default { uploadMedia };
