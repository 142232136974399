import React, { useState } from 'react';
import { IonButton, IonIcon, IonInput, IonItem, IonLabel, IonList, IonText } from '@ionic/react';
import { Controller, useForm } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { useDispatch } from 'react-redux';
import { hideLoader, showLoader } from '../../actions/loaderActions';
import { warningOutline } from 'ionicons/icons';
import ValidationError from '../../errors/ValidationError';
import { Violation } from '../../types/apiTypes';
import useRegisterUser from '../../hooks/useRegisterUser';
import { useTranslation } from 'react-i18next';
import config from '../../config/config';

interface Props {
  onSuccess: () => void;
}

interface FormData {
  email: string;
  password: string;
}

const RegisterForm: React.FC<Props> = ({ onSuccess }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { control, errors, handleSubmit, setError } = useForm<FormData>();
  const [contextError, setContextError] = useState<string | null>(null);

  const onRegisterUserSuccess = () => {
    dispatch(hideLoader());
    onSuccess();
  };

  const onRegisterUserError = (error: Error) => {
    if (error instanceof ValidationError) {
      error.violations.forEach((violation: Violation) => {
        console.log(violation);
        // @ts-ignore TS2345 Argument of type 'string' is not assignable to parameter of type '"password"'.
        setError(violation.propertyPath, { type: 'manual', message: violation.message });
      });
    } else {
      setContextError(t('error.something_went_wrong'));
    }
    dispatch(hideLoader());
  };

  const { registerUser } = useRegisterUser(onRegisterUserSuccess, onRegisterUserError);

  const handleFormSubmit = async (data: FormData): Promise<void> => {
    dispatch(showLoader());
    setContextError(null);
    registerUser.mutate({ email: data.email, password: data.password });
  };

  return (
    <form onSubmit={handleSubmit(handleFormSubmit)} noValidate={true}>
      <IonList>
        {contextError && (
          <IonItem lines="full">
            <IonIcon icon={warningOutline} color="danger" slot="start" />
            <IonText color="danger">{contextError}</IonText>
          </IonItem>
        )}
        <IonItem>
          <IonLabel position="floating" color="primary">{t('label.email')}</IonLabel>
          <Controller
            name="email"
            control={control}
            defaultValue={false}
            rules={{
              required: { value: true, message: t('validation.please_enter_your_email') },
              validate: (data: string) => {
                if(!/^[A-Z0-9._%+-]+@(?:[A-Z0-9-]+\.)+[A-Z]{2,}$/i.test(data)) {
                // if(!/^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/i.test(data)) {
                  return String(t('validation.invalid_email'));
                }
              }
            }}
            render={({ value, onChange, onBlur }) => {
              return (
                <IonInput
                  type="email"
                  inputmode="email"
                  name="email"
                  value={value}
                  required={true}
                  onIonChange={onChange}
                  onIonBlur={onBlur}
                  autofocus={true}
                  data-cy="RegisterForm-Input-Email"
                />
              );
            }}
          />
        </IonItem>
        <ErrorMessage name="email" errors={errors} render={ ({ message }) => (
          <IonText color="danger" data-cy="RegisterForm-ValidationError-Email">
            <p className="ion-padding-start ion-text-start">{message}</p>
          </IonText>
        )}/>

        <IonItem>
          <IonLabel position="floating" color="primary">{t('label.password')}</IonLabel>
          <Controller
            name="password"
            control={control}
            defaultValue={false}
            rules={{
              required: { value: true, message: t('validation.please_enter_your_password') },
              minLength: {
                value: config.userProfile.passwordMinLength,
                message: t('validation.password_should_have_at_least_characters', { count: config.userProfile.passwordMinLength })
              }
            }}
            render={({ value, onChange, onBlur }) => {
              return (
                <IonInput
                  type="password"
                  name="password"
                  clearOnEdit={true}
                  value={value}
                  required={true}
                  onIonChange={onChange}
                  onIonBlur={onBlur}
                  data-cy="RegisterForm-Input-Password"
                />
              );
            }}
          />
        </IonItem>
        <ErrorMessage name="password" errors={errors} render={({ message }) => (
          <IonText color="danger" data-cy="register-form-password-error">
            <p className="ion-padding-start ion-text-start">{message}</p>
          </IonText>
        )}/>

      </IonList>
      <IonButton type="submit" expand="full" size="default" color="primary" data-cy="RegisterForm-Button-Submit">{t('button.create')}</IonButton>
    </form>
  );
};

export default RegisterForm;
