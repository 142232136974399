import React from 'react';
import {
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonCol,
  IonGrid,
  IonRow
} from '@ionic/react';
import ProfileCompletionStep1Form from '../../components/forms/ProfileCompletionStep1Form';
import { Trans, useTranslation } from "react-i18next";

interface Props {
  onSuccess: () => void;
}

const ProfileCompletionStep1: React.FC<Props> = ({ onSuccess }) => {
  const { t } = useTranslation();

  const handleStepOneFormSuccess = () => {
    onSuccess();
  };

  return (
    <IonGrid>
      <IonRow className="ion-justify-content-center">
        <IonCol sizeSm="8" sizeMd="7" sizeLg="6" sizeXl="5">
          <IonCard>
            <IonCardHeader>
              <IonCardTitle>{t('profile_completion_step_1_title')}</IonCardTitle>
            </IonCardHeader>

            <IonCardContent>
              <Trans i18nKey="profile_completion_step_1_content" />
            </IonCardContent>

            <IonCardContent>
              <ProfileCompletionStep1Form onSuccess={handleStepOneFormSuccess} />
            </IonCardContent>
          </IonCard>
        </IonCol>
      </IonRow>
    </IonGrid>
  );
};

export default ProfileCompletionStep1;
