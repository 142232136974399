import React from 'react';
import WebAppToolbar from '../toolbar/WebAppToolbar';
import Layout from './Layout';

const WebAppLayout: React.FC = ({ children }) => {
  return (
    <Layout toolbar={<WebAppToolbar />}>
      {children}
    </Layout>
  );
};

export default WebAppLayout;
