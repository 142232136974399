import { setupConfig } from '@ionic/react';
import { createAnimation } from '@ionic/core';
import { IonicConfig } from '@ionic/core/dist/types/utils/config';

const getConfig = (): IonicConfig => {
  return {
    animated: true,
    navAnimation: () => {
      return createAnimation();
    },
  };
}

setupConfig(getConfig());
