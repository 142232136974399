import React, { useState } from 'react';
import {
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonItem,
  IonRow,
  IonText,
  IonToolbar,
} from '@ionic/react';
import { Link } from 'react-router-dom';
import { LoginWithFacebookButton } from '../FacebookLoginButton';
import { warningOutline } from 'ionicons/icons';
import { Trans, useTranslation } from 'react-i18next';
import { AuthModalAction } from '../../types/authModalTypes';

interface Props {
  onCloseButtonClick: () => void;
  onSetAuthModalAction: (action: AuthModalAction) => void;
  onSuccess: () => void;
}

const ModalContentLogin: React.FC<Props> = ({ onCloseButtonClick, onSetAuthModalAction, onSuccess }) => {
  const { t } = useTranslation();
  const [fbAuthError, setFbAuthError] = useState<string | null>(null);

  const handleCloseButtonClick = () => {
    onCloseButtonClick();
  };

  const handleLoginClassicLinkClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    onSetAuthModalAction('login-classic');
  };

  const handleRegisterLinkClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    onSetAuthModalAction('register');
  };

  const handleFacebookButtonSuccess = () => {
    setFbAuthError(null);
    onSuccess();
  };

  const handleFacebookButtonError = (error: Error) => {
    setFbAuthError(error.message);
  };

  return (
    <>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="end">
            <IonButton onClick={handleCloseButtonClick}>{t('modal.close')}</IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonGrid>
          <IonRow className="ion-justify-content-center">
            <IonCol sizeSm="10">
              <IonCard>
                <IonCardHeader>
                  <IonCardTitle className="ion-text-center"><h1>{t('login_page.title')}</h1></IonCardTitle>
                </IonCardHeader>
                <IonCardContent className="ion-text-center">
                  {fbAuthError && (
                    <IonItem lines="none" className="ion-padding-bottom">
                      <IonText color="danger">
                        <p>{fbAuthError}</p>
                      </IonText>
                      <IonIcon icon={warningOutline} color="danger" slot="start" />
                    </IonItem>
                  )}
                  <div>
                    <LoginWithFacebookButton
                      onSuccess={handleFacebookButtonSuccess}
                      onError={handleFacebookButtonError}
                    />
                  </div>
                  <div className="ion-padding-top">
                    {t('login_page.easy_fast_and_safe')}
                  </div>
                  <div className="ion-padding-top ion-text-uppercase">
                    - {t('_or')} -
                  </div>
                  <div className="ion-padding-top">
                    <Trans
                      i18nKey="login_page.log_in_with_an_email"
                      components={{ LINK: <Link to="/#" onClick={handleLoginClassicLinkClick} data-cy="ModalContentLogin-Link-LoginWithEmail" /> }}
                    />
                  </div>
                  <div className="ion-padding-top ion-text-uppercase">
                    - {t('_or')} -
                  </div>
                  <div className="ion-padding-top">
                    <div>{t('login_page.dont_have_a_profile_yet')}</div>
                    <div>
                      <Trans
                        i18nKey="login_page.create_a_profile_instead"
                        components={{ LINK: <Link to="/#" onClick={handleRegisterLinkClick} /> }}
                      />
                    </div>
                  </div>
                </IonCardContent>
              </IonCard>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </>
  );
};

export default ModalContentLogin;
